.which-track {
  padding-top: 60px;
  margin-top: 90px;
  background: rgba(96, 76, 172, 0.05);
}
.which-track .small-heading {
  color: var(--heading-color);
}
.which-track h3 {
  color: var(--first-color);
  font-weight: 600;
}
.which-track h6 {
  font-weight: 600;
  color: var(--heading-color);
  font-size: 17px;
}
.which-track p {
  color: var(--heading-color);
  line-height: 1.8;
}
