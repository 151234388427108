.welcome-section {
  background: url(../../../images/welcome.webp) center center/cover no-repeat;
  min-height: 60vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcome-section h2 {
  font-weight: 600;
  font-size: 46px;
  width: 42%;
  line-height: 1.5;
  padding: 0 20px;
}
.welcome-section span {
  color: var(--second-color);
}

/* Media */
@media (max-width: 992px) {
  .welcome-section h2 {
    width: 100%;
    font-size: 30px;
  }
}
