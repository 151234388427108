  /* styling for course details*****************************  */
  .wrapper-course-details {
    min-height: 1000px;
    width: 100%;
    color: #ffffff;
  }
  .Head-course-details-parallax {
    position: relative;
    height: 300px;
    background-image: url("../../images/real-back.png");
    /* background-attachment: fixed;
    background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
  }
  .course-category-heighlight {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 47%;
    padding: 25px 0px;
    margin: auto;
    color: #ffffff;
  }
  /* .course-details {
    text-align: center;
    width: 100%;
    padding: 50px;
  }
  .course-details img {
    display: block;
    margin: 50px auto;
    width: 40%;
  } */

  /*----------------------- Course details ********************************-------------------*/
.course-details {
  padding: 100px 0px 0px 0px;
  color: #000;
}
.course-details ul {
  list-style-type: none;
}
.course-sidebar .sidebar-course-information {
  border: 1px solid rgba(0,0,0,0.06);
  border-radius: 3px;
  margin-bottom: 65px;
  padding: 30px;
}
.course-sidebar .sidebar-course-information .alert-info span {
  font-weight: bold;
  text-decoration: underline;
}
.course-sidebar .sidebar-course-information .price strong {
  font-family: 'Montserrat', sans-serif;
  line-height: 30px;
  font-weight: 700;
  font-size: 24px;
}
.course-sidebar .sidebar-course-information .price strong sup {
  font-size: 12px;
  top:-8px;
}
.course-sidebar .sidebar-course-information .price li {margin-bottom: 25px;}
.course-sidebar .sidebar-course-information .price strong del {
  color: #c7c7c7;
  margin-right: 20px;
}
.course-sidebar .sidebar-course-information .price li a {
  line-height: 30px;
  font-size: 30px;
}
.course-sidebar .sidebar-course-information .price {
  border-bottom: 1px solid rgba(0,0,0,0.06);
  margin-bottom: 25px;
}
.course-sidebar .sidebar-course-information .info-list li {
  color: #9d9d9d;
  margin-bottom: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.course-sidebar .sidebar-course-information .extr-inf {
  border-top: 1px solid rgba(0,0,0,0.06);
  border-bottom: 1px solid rgba(0,0,0,0.06);
  display: flex;
  flex-flow: row;
  padding: 20px 0px;
  justify-content: space-between;
}
.course-sidebar .sidebar-course-information .extr-inf i {
  font-size: 40px;
  color:  var(--first-color);
  margin-right: 20px;
}
.course-sidebar .sidebar-course-information .extr-inf .students p {
 margin-bottom: 0px;
 text-align: center;
}
.course-sidebar .sidebar-course-information .take-course {
  display: block;
  width: 100%;
  line-height: 45px;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  margin: 20px 0px;
}
.course-sidebar .sidebar-course-information .take-exam {
  width: fit-content;
  margin: auto;
}
.course-sidebar .sidebar-course-information .label-question {
  color: var(--heading-color);
  font-weight: bold;
  margin-bottom: 3px;
  font-size: 14px;
  text-transform: capitalize;
  cursor: pointer;
}
.label-question span {
  color: var(--cancel-color);
  font-size: 12px;
  font-weight: lighter;
}
.course-sidebar .sidebar-instructor-info .wrapper{
  border: 1px solid rgba(0,0,0,0.06);
  border-radius: 3px;
  margin-bottom: 65px;
  padding: 30px 25px;
}
.course-sidebar .sidebar-instructor-info .name {
  position: relative;
  padding-left: 105px;
  color: var(--heading-color);
}
.course-sidebar .sidebar-instructor-info .name h6 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: var(--first-color);
}
.course-sidebar .sidebar-instructor-info .name span {
  display: block;
  margin: 5px 0 15px 0;
}
.course-sidebar .sidebar-instructor-info .name ul li {display: inline-block;}
.course-sidebar .sidebar-instructor-info .name ul li a {
  color: var(--first-color);
  margin-right: 10px;
}
.course-sidebar .sidebar-instructor-info .name ul li a i {
  cursor: pointer;
  font-size: 22px;
  transition: 0.2s;
}
.course-sidebar .sidebar-instructor-info .name ul li a i:hover {
  opacity: 0.7;
}
.course-sidebar .sidebar-instructor-info .name img {
  position: absolute;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  left:0;
  top:-4px;
}
.course-sidebar .sidebar-instructor-info p {color: var(--heading-color); margin: 20px 0 25px 0;}
.course-sidebar .sidebar-instructor-info .follow {
  display: block;
  width: 100%;
  line-height: 45px;
  border-radius: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
}

.course-details .details-wrapper iframe {
  display: block;
  width: 100%;
  height: 450px;
  margin: auto;
  border-radius: 10px;
}
.course-details .details-wrapper .lesson-instructions {
  padding: 30px;
  background-color: white;
  font-family: cursive;
  margin: 90px 0px;
  border: 2px solid skyblue;
  border-radius: 10px;
}
.course-details .details-wrapper .lesson-instructions p {
  white-space: pre-line;
}
.course-details .details-wrapper .course-title h2 {line-height: 50px; color: var(--first-color);}
.course-details .details-wrapper .course-title span {
  font-size: 16px;
  display: block;
  margin: 5px 0 0 0;
  color: rgba(0,0,0,0.5);
}
.course-details .details-wrapper .course-info {
  border: 1px solid rgba(0,0,0,0.05);
  margin:35px 0 60px 0;
}
.course-details .details-wrapper .course-info .rating-coursedetails-header {
  padding: unset !important;
}
.course-details .details-wrapper .course-info [class*="col-"] {padding: 0;}
.course-details .details-wrapper .course-info [class*="col-"] div {
  position: relative;
  padding: 25px 5px 35px 85px;
  border-right: 1px solid rgba(0,0,0,0.05);
}
.course-details .details-wrapper .course-info [class*="col-"] div>b {
  font-size: 14px;
}
.course-details .details-wrapper .course-info [class*="col-"] div p {
  color: var(--heading-color);
  font-weight: 600;
}

.course-details .details-wrapper .course-info [class*="col-"] div>i {
  position: absolute;
  left: 30px;
  font-size: 40px;
  top:25px;
  color: var(--first-color);
  margin-top: 15px;
}
/* .course-details .details-wrapper .course-info [class*="col-"] div ul {
  margin-left: 15px;
} */
.course-details .details-wrapper .course-info [class*="col-"] div ul li {
  display: inline-block;
  color: #ffc208;
}
.course-details .details-wrapper p.p1 {margin: 50px 0;}
.course-details .details-wrapper h3 {
  font-weight: 700;
  margin-bottom: 30px;
  color: var(--first-color);
}
.course-details .details-wrapper .learn-list ul li span {
  display: block;
  position: relative;
  line-height: 26px;
  padding-left: 20px;
  margin-bottom: 15px;
}
.course-details .details-wrapper .learn-list ul li span:before {
  content: '';
  position: absolute;
  width: 7px;
  height: 7px;
  border: 1px solid #e7e7e7;
  border-radius: 50%;
  top:11px;
  left:0;
}
.course-details .details-wrapper .curriculum-panel .head {
  display: flex;
  justify-content: space-between;
}
.course-details .details-wrapper .curriculum-panel .head>ul li {
  font-weight: 600;
  display: inline-block;
  margin-left: 30px;
  line-height: 30px;
  color: var(--heading-color);
}
.course-details .details-wrapper .curriculum-panel .panel-group {
  margin: 0;
  border-top:1px solid rgba(0,0,0,0.05);
}
.course-details .details-wrapper .curriculum-panel .panel-heading {
  padding: 0;
  border-bottom:1px solid rgba(0,0,0,0.05);
  border-left: none;
  border-right:none;
  border-radius: 0;
  background: transparent;
}
.course-details .details-wrapper .curriculum-panel .panel-heading a {
  padding: 25px 0 25px 75px;
  font-weight: normal;
  display: flex;
  position: relative;
  justify-content: space-between;

}
.course-details .details-wrapper .curriculum-panel .panel-heading a h6{
  font-family: 'Lato', sans-serif;
  color: #000000;
  font-size: 18px;
  font-weight: 700;
}
.course-details .details-wrapper .curriculum-panel .panel-heading a h6 span {
  text-transform: uppercase;
  color: var(--heading-color);
  font-weight: 500;
  font-size: 13px;
  display: block;
  margin-bottom: 8px;
}
.course-details .details-wrapper .curriculum-panel .panel-heading p {
  color: var(--heading-color);
  font-weight: 600;
  font-size: 14px;
}
.course-details .details-wrapper .curriculum-panel .panel-heading a:before {
  content: '+';
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid #e7e7e7;
  color: rgba(0,0,0,0.3);
  position: absolute;
  font-size: 35px;
  font-weight: 300;
  left:0;
  top:50%;
  -webkit-transform:translateY(-50%);
          transform:translateY(-50%);
}
.course-details .details-wrapper .curriculum-panel .panel-heading.active-panel {border-color: transparent;}
/* .course-details .details-wrapper .curriculum-panel .panel-heading.active-panel a:before {
  content: '+'
} */
.course-details .details-wrapper .panel-group .panel {
  margin: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
}
.course-details .details-wrapper .panel-group .panel .panel-body {
  background: transparent;
  padding:0 0 0 75px;
  border:none;
  border-bottom:1px solid rgba(0,0,0,0.05);
  margin-top: -20px;
}
.course-details .details-wrapper .panel-group .panel .panel-body ul {
  margin-top: 1rem !important;
  padding-left: unset;
}
.course-details .details-wrapper .panel-group .panel .panel-body .lesson .length {color: var(--heading-color);}
.course-details .details-wrapper .panel-group .panel .panel-body .lesson span {
  display: flex;
}
.course-details .details-wrapper .panel-group .panel .panel-body ul li {
  line-height: 60px;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  display: flex;
  justify-content: space-between;
}
.course-details .details-wrapper .panel-group .panel .panel-body ul li:last-child {border: none;}
.course-details .details-wrapper .panel-group .panel .panel-body ul li span i {
  margin-right: 12px;
  color: var(--first-color);
  font-size: larger;
}
.course-details .details-wrapper .panel-group .panel .panel-body ul li span a {
  display: flex;
  align-items: center;
  color: var(--heading-color);
}
.course-details .details-wrapper .panel-group .panel .panel-body ul li span a.preview {
  width: 70px;
  line-height: 25px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  margin-left: 15px;
  font-size: 13px;
}

.course-details .details-wrapper .curriculum-panel {margin: 50px 0 85px 0;}
.course-details .details-wrapper .course-feedback .feedback-container {
  border-bottom: 1px solid rgba(0,0,0,0.05);
  border-top: 1px solid rgba(0,0,0,0.05);
  padding: 28px 0 5px 0;
}
.course-details .details-wrapper .course-feedback .feedback-container .rate-num {
display: flex;
justify-content: space-between;
}
.course-details .details-wrapper .course-feedback .feedback-container .rate-num .rate-bar {
  display: flex;
  width: 100%;
}
.course-details .details-wrapper .course-feedback .feedback-container>ul>li:nth-child(1) {
  width: 20%;
  text-align: center;
}
.course-details .details-wrapper .course-feedback .feedback-container>ul>li:nth-child(1) h2 {
  font-size: 92px;
  font-weight: 500;
  color: var(--first-color);
}
.course-details .details-wrapper .course-feedback .feedback-container>ul>li:nth-child(1) p {
  color: rgba(0,0,0,0.4);
  margin: -7px 0 10px 0;
}
.course-details .details-wrapper .course-feedback .feedback-container>ul>li:nth-child(1) ul li {
  display: inline-block;
  margin: 0 2px;
  color: #ffc208;
}
.course-details .details-wrapper .course-feedback .feedback-container>ul>li:nth-child(1) p span {
  color: rgba(0,0,0,0.9);
  font-weight: 700;
}
.course-details .details-wrapper .course-feedback .feedback-container>ul>li:nth-child(2) {
  width: 80%;
  padding-left: 20px;
  margin-top: 18px;
  color: var(--heading-color);
}
.course-details .details-wrapper .course-feedback .feedback-container>ul>li:nth-child(2) ul li {
  float: left;
  line-height: 16px;
  margin-bottom: 20px;
}
.course-details .details-wrapper .course-feedback .feedback-container>ul>li:nth-child(2) ul li:nth-child(1) {
  margin-right: 15px;
  font-weight: 600;
}
.course-details .details-wrapper .course-feedback .feedback-container>ul>li:nth-child(2) ul li:nth-child(2) {
  height: 16px;
  background: #f2f2f2;
  border-radius: 3px;
  width: 55%;
  position: relative;
}
.course-details .details-wrapper .course-feedback .feedback-container>ul>li:nth-child(2) ul li:nth-child(2) div {
  position: absolute;
  top:0;
  left:0;
  bottom: 0;
  border-radius: 3px 0 0 3px;
  background-color: var(--second-color);
}
.course-details .details-wrapper .course-feedback .feedback-container>ul>li:nth-child(2) ul li:nth-child(3) {
  font-weight: 700;
  padding: 0 8px 0 20px;
}
.course-details .details-wrapper .course-feedback .single-review {
  border-bottom: 1px solid rgba(0,0,0,0.05);
  padding: 35px 0 35px 0;
  display: flex;
  justify-content: space-between;
}
.course-details .details-wrapper .course-feedback .single-review img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.course-details .details-wrapper .course-feedback .single-review .text {
  width: calc(100% - 70px);
  padding-left: 25px;
}
.course-details .details-wrapper .course-feedback .single-review .text .review-info {
  display: flex;
  justify-content: space-between;
}
.course-details .details-wrapper .course-feedback .single-review .text h6 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 5px 0;
  color: var(--heading-color);
}
.course-details .details-wrapper .course-feedback .single-review .text span {
  color: #b6b6b6;
}
.course-details .details-wrapper .course-feedback .single-review .text ul li {
  display: inline-block;
  margin:10px 2px 0 2px;
  color: #ffc208;
}
.course-details .details-wrapper .course-feedback .single-review .text p {
  margin-top: 15px;
  color: var(--heading-color);
  font-weight: 400;
  font-size: 16px;
}

.submit-review-form p {
  margin: -3px 0 8px 0;
}
.submit-review-form ul li {display: inline-block;color: rgba(0,0,0,0.3);}
.submit-review-form ul li:hover {
  color: gold;
}
/* .course-details .details-wrapper .submit-review-form form {margin-top: 35px;}
.course-details .details-wrapper .submit-review-form form label {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 18px;
  font-weight: bold;
} */
.submit-review-form form input[type="text"],
.submit-review-form form input[type="email"],
/* .course-details .details-wrapper .submit-review-form form textarea {
  width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 3px;
  margin-bottom: 30px;
  padding: 0 20px;
} */
.submit-review-form form input[type="text"],
.submit-review-form form input[type="email"] {height: 48px;}
/* .course-details .details-wrapper .submit-review-form form textarea {
  height: 260px;
  max-height: 260px;
  padding: 20px;
  background-color: aliceblue;
} */
/* .course-details .details-wrapper .submit-review-form form textarea:focus-visible {
  outline: none;
  border: solid 1px var(--first-color);
  background-color: aliceblue;
} */
.lesson-link-active {
  cursor: pointer;
}
.lesson-link-inactive {
  opacity: 0.7;
  cursor:default
}
.lesson-link-preview {
  cursor: pointer;
  margin-left: 30px;
}
.lesson-link-preview i {
  background-color: var(--second-color);
  color: var(--first-color) !important;
  /* border-color: #b6effb; */
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 14px !important;
  display: flex;
}
.lesson-link-preview i span {
  padding-left: 7px;
}

  /*----------------------- Course sideBar ********************************-------------------*/
.course-sidebar {
  margin-left: 30px;
}
.course-sidebar h4 {
  color: var(--heading-color);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}
.course-sidebar .sidebar-categories {
  padding: 40px 0 15px;
  border-bottom: 2px solid var(--page-background);
}
.course-sidebar .sidebar-categories .theme-accordion {
  border: 1px solid rgba(0,0,0,0.06);
  border-radius: 3px;
  margin-bottom: 50px;
}
.course-sidebar .sidebar-categories .theme-accordion .panel {
  border: none;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: transparent;
}
.course-sidebar .sidebar-categories .panel-heading {
  border-top: 1px solid rgba(0,0,0,0.06);
  padding: 0;
  border-radius: 0;
}
.course-sidebar .sidebar-categories .panel:first-child .panel-heading {border: none;}
.course-sidebar .sidebar-categories .panel-heading a {
  font-family: 'Lato', sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #404040;
  line-height: 48px;
  padding: 0 40px 0 20px;
  position: relative;
  display: block; 
}
.course-sidebar .sidebar-categories .panel-heading a:before {
  content: '+';
  font-family: 'font-awesome';
  color: #838383;
  line-height: 48px;
  position: absolute;
  right: 25px;
  top:0;
}
.course-sidebar .sidebar-categories .panel-heading.active-panel a:before{content: '';}
.course-sidebar .sidebar-categories .panel-heading.active-panel a,
.course-sidebar .sidebar-categories .panel .panel-body {background: #f9fafb;}
.course-sidebar .sidebar-categories .panel .panel-body {
  border: none;
  padding: 0 10px 10px 35px;
}
.course-sidebar .sidebar-categories .panel .panel-body ul li a {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #32394d;
  line-height: 40px;
  display: block;
}
.course-sidebar .main-wrapper {
  border: 1px solid rgba(0,0,0,0.06);
  border-radius: 3px;
}
.course-sidebar .main-wrapper h5 {
  font-weight: 500;
  font-size: 15px;
  padding: 16px 0 16px 20px;
}
.course-sidebar .main-wrapper .tag-option h5 {
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.course-sidebar .main-wrapper .tag-option {
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.course-sidebar .main-wrapper .tag-option ul {
  padding: 22px 10px 15px 10px;
  margin: 0 -2px;
}
.course-sidebar .main-wrapper .tag-option ul li {
  float: left;
  padding: 0 2px;
  margin-bottom: 10px;
}
.course-sidebar .main-wrapper .tag-option ul li label {
  position: relative;
  font-size: 13px;
  line-height: 30px;
  border: 1px solid transparent;
  border-radius: 15px;
  color: rgba(0,0,0,0.55);
  padding: 0 20px 0 12px;
  cursor: pointer;
}
.course-sidebar .main-wrapper .tag-option ul li input[type="checkbox"] {
  display: none;
}
.course-sidebar .main-wrapper .tag-option ul li label:before {
  content: '';
  line-height: 30px;
  font-weight: normal;
  font-size: 12px;
  display: inline-block;
  position: absolute;
  right:7px;
  top:0;
  opacity: 0;
}
.course-sidebar .main-wrapper .tag-option ul li input[type="checkbox"]:checked + label:before {
  content: "";
  font-family: 'font-awesome';
  opacity: 1;
}
.course-sidebar .main-wrapper .tag-option ul li input[type="checkbox"]:checked + label {border-color: #e7ebee;}
.course-sidebar .course-price-filter .price-ranger {
  padding: 0 20px 30px 20px;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.course-sidebar .course-price-filter .price-ranger .ranger-min-max-block li label {
  display: block;
  font-weight: normal;
  font-size: 14px;
  color: #848484;
  margin-bottom: 10px;
}
.course-sidebar .course-price-filter .price-ranger .ranger-min-max-block li {margin: 0 15px 30px 0;}
.course-sidebar .course-price-filter .price-ranger .ranger-min-max-block input {
  width: 60px;
  height: 30px;
  border: 1px solid #e7ebee;
  border-radius: 4px;
  text-align: center;
  color: #848484;
}
.course-sidebar .course-price-filter .price-ranger .ui-widget-content {
  background: #eef2f5;
  border: none;
  height:5px;
  border-radius: 0;
}
.course-sidebar .course-price-filter .price-ranger .ui-slider-handle {
  width: 15px;
  height: 15px;
  background: #fff;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
}
.course-sidebar .course-price-filter .price-ranger .ui-slider-horizontal .ui-slider-handle {
  top: -5px;
}
.course-sidebar .main-wrapper .course-work-level ul li label {
  position: relative;
  font-size: 14px;
  line-height: 30px;
  padding-left: 20px;
  color: rgba(0,0,0,0.55);
  cursor: pointer;
}
.course-sidebar .main-wrapper .course-work-level ul li input[type="checkbox"] {
  display: none;
}
.course-sidebar .main-wrapper .course-work-level ul li label:before {
  content: '';
  line-height: 30px;
  font-weight: normal;
  font-size: 12px;
  display: inline-block;
  position: absolute;
  right:-17px;
  top:0;
  opacity: 0;
}
.course-sidebar .main-wrapper .course-work-level ul li input[type="checkbox"]:checked + label:before {
  content: "";
  font-family: 'font-awesome';
  opacity: 1;
}
.course-sidebar .main-wrapper .course-work-level {
  border-bottom: 1px solid rgba(0,0,0,0.06);
  padding-bottom: 15px;
}
.course-sidebar .main-wrapper .course-location {padding: 0 20px 22px 20px;}
.course-sidebar .main-wrapper .course-location h5 {
  padding-left: 0;
}
.course-sidebar .main-wrapper .course-location .bootstrap-select > .dropdown-toggle {
  height: 38px;
  padding-left: 0;
  border-bottom: 1px solid rgba(0,0,0,0.04);
}
.course-sidebar .main-wrapper .bootstrap-select.btn-group .dropdown-toggle .bs-caret {border: none;}
.course-sidebar .main-wrapper .course-location .bootstrap-select.btn-group .dropdown-toggle .bs-caret:before {
  content: "\f10b";
  font-family: Flaticon;
  line-height: 38px;
  border: none;
  font-size: 20px;
}
.course-sidebar .main-wrapper .course-location .bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark:before {
  content: '';
  font-family: 'font-awesome';
  line-height: 40px;
  color: #fff;
}
.course-sidebar .main-wrapper .course-location .bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  top:0;
  margin: 0;
}
.course-sidebar .main-wrapper .course-location .dropdown-menu > .active > a span.text,
.course-sidebar .main-wrapper .course-location .dropdown-menu > .active > a:hover span.text,
.course-sidebar .main-wrapper .course-location .dropdown-menu > .active > a:focus span.text {color: #fff;}
.course-sidebar .main-wrapper .course-location .dropdown-menu {max-height: 300px !important;}
.course-sidebar .main-wrapper .button-wrapper {padding: 0 20px 25px 20px;}
.course-sidebar .main-wrapper input[type="submit"] {
  display: block;
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}

/* ************ExamScreen Modal************* */
.course-exam-modal {
  border-radius: 40px;
  background-color: #ffffff;
  max-width: 800px;
  margin: 80px auto 0px auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  color: var(--heading-color);
}
.course-exam-modal h3 {
  color: var(--first-color);
  margin-bottom: 10px;
}
.course-exam-modal p {
  font-weight: 500;
  font-size: 14px;
}
.course-exam-modal .exam-wrapper {
  border-radius: 10px;
  padding: 15px 10px;
  border: 1px solid var(--tag-background);
}
.course-exam-modal .exam-wrapper .exam-info {
  background: #fff;
  padding: 15px 20px;
  max-height: 400px;
  overflow-y: scroll;
}

/* ************jobs section************* */
.job-course-matchs {
  margin-top: 50px;
  border-top: 1px solid rgba(0,0,0,0.05);
  padding: 50px 20px 30px 20px;
  background: #cdc8ca1f url(../../images/clear-path/clear-path-bac.png)
    center center/cover no-repeat;
  background-position: bottom;
  background-size: contain;
  text-align: center;
  min-height: 600px;
}
.job-course-matchs h3 {
  font-weight: 700;
  margin-bottom: 40px;
  color: var(--first-color);
  text-transform: capitalize;
}
.job-course-matchs .jobcards-container {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 80px;
}
@media (max-width: 600px) {
  .job-course-matchs .jobcards-container {
    padding: 20px 10px;
  }
  .card-job-info {
    width: 200px;
  }
}