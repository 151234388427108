.payment-card {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.payment-card h6 {
  margin: 30px 0px 5px 0px;
}
.payment-card .payment-container {
  display: flex;
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  border: thin solid rgb(190, 190, 190);
  justify-content:space-between;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
  margin: 5px 0px;
  background-color: white;
}
/* .payment-card .payment-container:focus {
  border: thin solid var(--specialVioletColor);
  background-color: rgb(240, 240, 240);
} */
.payment-card .payment-container .option {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
}
.payment-card .payment-container .option .icon {
  font-size: 40px;
  display: flex;
  justify-content: flex-end;
  color: var(--first-color);
}
.payment-card .payment-container .option .label {
  margin-left: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.payment-card .payment-container .outer-circle {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 7px;
  background-color: #ffffff;
  box-shadow: var(--first-color);
  border: thin solid rgb(190, 190, 190);
}
.payment-card .payment-container.active {
  background-color: rgba(95, 76, 172, 0.15);
}
.payment-card .payment-container.active .outer-circle {
  background-color: var(--first-color);
  box-shadow: #892be285;
}
.payment-card .payment-container.passive .outer-circle {
  background-color: #ffffff;
}
.payment-card .payment-container .inner-circle {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
}