.our-mission {
  padding: 100px 0;
  background-color: lavender;
}

.our-mission .row .mission_img {
  max-height: 440px;
  float: right;
}

.our-mission h2 {
  color: var(--first-color);
  font-weight: 600;
}

.our-mission p {
  line-height: 1.8;
  color: var(--heading-color);
  font-size: 16px;
}