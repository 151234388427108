.member-card-container {
  padding: 90px 0;
}
.member-card-container {
  background: #cdc8ca1f url(../../../images/clear-path/clear-path-bac.png)
    center center/cover no-repeat;
}
.member-card-container h2 {
  color: var(--first-color);
  font-weight: 600;
}