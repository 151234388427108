.course-card {
  padding: 20px 0;
}
.course-card .card {
  border-radius: 17px;
  box-shadow: 0px 0px 1px 0 #aeaeae;
  border-color: #f0f0f0;
  padding: 10px 10px 1px 10px;
  cursor: pointer;
  transition: 0.3s;
}
.course-card .card:hover {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
}
.course-card .card:hover .card-title {
  color: var(--first-color);
}
.course-card .card_img .icon-img {
  width: 50px;
  position: absolute;
  right: 33px;
  bottom: -20px;
}
.course-card .card_img .discount-notice {
  padding: 5px 20px;
  width: fit-content;
  background-color: var(--second-color);
  color: var(--first-color);
  position: absolute;
  left: 33px;
  bottom: -18px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
}
.course-card .card-title {
  color: var(--heading-color);
  line-height: 1.5;
  font-weight: 600;
  margin-top: 10px;
  text-transform: capitalize;
  min-height: 60px;
}
.course-card .card-text {
  color: var(--heading-color);
  font-size: 13px;
  line-height: 2;
  height: 100px;
}
.course-card .other-courses {
  padding: 10px 0;
}
.course-card .other-courses span {
  background: rgba(96, 76, 172, 0.1);
  margin: 0 3px;
  padding: 7px;
  border-radius: 10px;
  font-size: 12px;
  color: var(--first-color);
}
.course-card .secondary-details {
  padding: 0px 11px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.course-card .secondary-details .img-wrapp span {
  color: var(--heading-color);
  font-weight: 500;
}
.course-card .secondary-details .img-wrapp img {
  width: 50px;
  height: 50px;
}

.course-card .secondary-details .info-wrapp {
  display: flex;
  flex-direction: column;
}
.course-card .secondary-details .sub-tag {
  color: var(--first-color);
  border: none;
  background: var(--tag-background);
  border-radius: 5px;
  font-size: 14px;
  padding: 2px 7px;
  margin-bottom: 5px;
  font-weight: 500;
}
.course-card .secondary-details .sub-tag i {
  margin-right: 10px;
}

.course-card hr {
  color: #c0b2b2;
  margin: 0.5rem 0 !important;
}
/* Media */
@media (min-width: 768px) and (max-width: 991.98px) {
  .course-card .card-title {
    font-size: 16px;
  }
}
