
.signup-screen .sign-input,
.signup-popup-screen .sign-input,
.signin-screen .sign-input {
  border-radius: 5px;
  color: var(--heading-color);
  background-color: rgb(250, 250, 250);
  border: solid 1px #d8d8d8;
  height: 40px;
  /* border-color: var(--heading-color) !important; */
  padding: 10px 5px;
}
.signup-screen .sign-input:focus-visible,
.signup-popup-screen .sign-input:focus-visible,
.signin-screen .sign-input:focus-visible {
  /* border-style: none; */
  outline: none;
  border: solid 1px var(--first-color);
  background-color: aliceblue;
}


/* ****signup screen for signup page***** */
.signup-screen {
  max-width: 600px;
  margin: auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  color: var(--heading-color);
}
.signup-screen h3 {
  color: var(--first-color);
  margin: 10px 0px;
}
.signup-screen .cancel-button {
  display: none;
}

.signup-screen .inputs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px auto;
  justify-content: space-between;
}
.signup-screen .inputs-wrapper .half-width-cell {
  display: flex;
  flex-direction: column;
  width: 47%;
}
.signup-screen .inputs-wrapper .half-width-cell .half-input {
  margin: 2px 0px 7px;
}

.signup-screen .signup-recaptcha {
  margin: 10px auto;
  width: fit-content;
}
.signup-screen .signup-recaptcha iframe {
  background: unset !important;
  padding: unset !important;
}
.signup-screen .alternat-sign-form {
  text-align: center;
}
.signup-screen .alternat-sign-form a {
  color: var(--first-color);
}

/* This part is common for all****Start*** */
.sign-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sign-form .text-input {
  width: 100%;
  margin: 2px 0 15px 0;
}

.sign-form .grantcode-entry label i {
  color: var(--second-color);
  margin-left: 7px;
}
.sign-form .grantcode-entry label i:hover {
  cursor: help;
  position: relative;
}
.sign-form .grantcode-entry label i span {
  display: none;
  font-family: system-ui;
  white-space: pre-line;
  line-height: 20px;
}
.sign-form .grantcode-entry label i:hover span {
  padding: 10px;
  border-radius: 10px;
  display: block;
  color: #ffffff;
  background-color: var(--first-color);
  z-index: 100;
  left: 5px;
  margin: 10px;
  width: 250px;
  position: absolute;
  top: 10px;
  text-decoration: none;
}
/* This part is common for all***End*** */

.singup-socialmedia {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: space-between;
  align-items:center;
}
.singup-socialmedia .line {
  margin: auto;
  height: 1px;
  width: 35%;
  background-color: rgba(112, 112, 112, 0.5);
}
.singup-socialmedia .singup-with-text {
  width: fit-content;
  padding: 0px 5px;
  margin: auto;
  font-size: 16px;
}
.socialmedia-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  justify-content: space-between;
  align-items:center;
  flex-wrap: wrap;
}

/* *****signup screen for promotional signup window**** */
.signup-popup-screen {
  border-radius: 40px;
  font-size: 13px;
  background-color: #ffffff;
  max-width: 600px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  color: var(--heading-color);
}

.signup-popup-screen .inputs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px auto;
  justify-content: space-between;
}
.signup-popup-screen .inputs-wrapper .half-width-cell {
  display: flex;
  flex-direction: column;
  width: 47%;
}
.signup-popup-screen .inputs-wrapper .half-width-cell .half-input {
  margin: 2px 0px 7px;
}

.signup-popup-screen .alternat-sign-form {
  display: none;
}
.signup-popup-screen .signup-recaptcha {
  margin: 1px auto;
  width: fit-content;
}
.signup-popup-screen .signup-recaptcha iframe {
  background: unset !important;
  padding: unset !important;
}
.signup-popup-screen h3 {
  color: var(--first-color);
  margin-bottom: 10px;
}
.signup-popup-screen p {
  font-weight: 500;
  margin-bottom: 5px;
}
.signup-popup-screen .cancel-button {
  width: 100%;
  height: fit-content;
}
.signup-popup-screen .cancel-button .cancel-wrapper {
  width: fit-content;
  float: right;
  cursor: pointer;
}
.signup-popup-screen .cancel-button img {
  display: block;
  height: 20px;
  width: 20px;
}
.signup-popup-screen .cancel-button img:hover {
  opacity: 0.7;
}

/* ****signin screen for signin page***** */
.signin-screen {
  width: 40%;
  max-width: 600px;
  margin: 70px auto;
  padding: 50px;
  display: flex;
  flex-direction: column;
  color: var(--heading-color);
  background-color: #ffffff;

}
.signin-screen h3,
.signin-screen p,
.signin-screen .cancel-button,
.signin-screen .inputs-wrapper.exclude-for-signin,
.signin-screen .sign-form .grantcode-entry,
.signin-screen .signup-recaptcha {
  display: none;
}

.signin-screen .inputs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 5px auto;
}
.signin-screen .inputs-wrapper .half-width-cell {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.signin-screen .inputs-wrapper .half-width-cell .half-input {
  margin: 2px 0px 7px;
}

.signin-screen .alternat-sign-form {
  text-align: center;
  margin-top: 5px;
}
.signin-screen .alternat-sign-form a {
  margin-top: 15px;
  color: var(--heading-color);
  font-size: 14px;
  opacity: 0.6;
}
.signin-screen .alternat-sign-form a:hover {
  opacity: 1;
}

/* Responiveness */
@media (max-width: 700px) {
  .signup-screen, .signup-popup-screen, .signin-screen {
    width: 95%;
    padding: 20px;
  }
  .singup-socialmedia {
    flex-direction: column;
  }
  .socialmedia-buttons-container {
    justify-content: center;
  }
  .sign-form .text-input {
    margin: 5px 0px;
  }
}