.ongoing-courses h2 {
  color: var(--first-color);
  font-weight: 600;
}
.ongoing-courses .scroll-courses {
  background-color: #fff;
  padding: 15px 0;
  text-align: center;
}
.ongoing-courses .scroll-courses button {
  margin: 0 15px;
  background: transparent;
  color: #604cac99;
  border: none;
  text-transform: capitalize;
  margin-bottom: 20px;
  transition: 0.1s;
}
.ongoing-courses .scroll-courses button:hover {
  color: var(--first-color);
}
.ongoing-courses .scroll-courses .active {
  color: var(--first-color);
  border-bottom: 3px solid #7ef0ac;
}
/* Media */
@media (max-width: 768px) {
  .ongoing-courses .scroll-courses {
    overflow: auto;
    white-space: nowrap;
  }
}
