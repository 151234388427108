/* -- Learn More Link -- */
.learn-more-link a,
.learn-more-link button {
  background: transparent;
  border: none;
  padding: 10px 0;
  position: relative;
  text-transform: capitalize;
  cursor: pointer;
}

.learn-more-link a i {
  transition: 0.3s;
}

.learn-more-link a:hover,
.learn-more-link button:hover {
  opacity: 0.8 !important;
}

.learn-more-link a:hover i {
  padding-left: 10px;
}

.learn-more-link i {
  font-size: 13px;
}

/* -- Learn More Button -- */
.learn-more-btn button,
.learn-more-btn a,
.learn-more-btn input {
  border: none;
  padding: 10px 30px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  background: var(--second-color);
  color: var(--first-color) !important;
  cursor: pointer;
}

.learn-more-btn.full-width {
  width: 100%;
  margin-top: 15px;
}

.learn-more-btn.disabled button {
  opacity: 0.5;
  background-color: rgb(241, 241, 241);
  color: var(--heading-color) !important;
  cursor: unset !important;
}

.learn-more-btn.disabled button:hover {
  opacity: 0.5 !important;
}

.learn-more-btn.full-width.Xmar {
  margin-top: 25px;
  display: flex;
}

.learn-more-btn.flex {
  margin-top: 20px;
  width: 100%;
  display: flex;
}

.learn-more-btn.flex button {
  margin: auto;
  text-transform: uppercase;
}

.learn-more-btn.flex button i {
  padding-left: 7px;
  transition: 0.3s;
}

.learn-more-btn.flex button:hover i {
  padding-left: 20px;
}

.learn-more-btn.full-width button,
.learn-more-btn.full-width input,
.learn-more-btn.full-width a {
  width: 100%;
  text-align: center;
}

.learn-more-btn.upper-case button,
.learn-more-btn.upper-case input {
  text-transform: uppercase;
  font-size: 15px;
  padding: 15px 30px;
}

.learn-more-btn.confirm button {
  color: var(--first-color);
  background-color: var(--second-color);
  font-size: 16px;
  padding: 10px 35px;
}

.learn-more-btn.cancel button {
  color: #ffffff !important;
  background-color: var(--cancel-color);
  font-size: 16px;
  padding: 10px 35px;
}

.cancel-order {
  display: flex;
  margin-top: 20px;
}

.cancel-order button {
  margin: auto;
  color: var(--cancel-color);
  background-color: unset;
  border: 1px solid var(--cancel-color);
  font-size: 16px;
  padding: 10px 20px;
  text-transform: uppercase;
  border-radius: 10px;
}

.cancel-order button i {
  transform: rotate(180deg);
  padding-left: 7px;
  transition: 0.3s;
}

.cancel-order button:hover i {
  padding-left: 15px;
}

.learn-more-btn.prompt-link {
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.learn-more-btn.prompt-link a {
  width: 50%;
  text-align: center;
}

.learn-more-btn button:hover,
.learn-more-btn a:hover,
.learn-more-btn input:hover {
  opacity: 0.7;
  color: var(--first-color);
}

/* framed button e.g. signup button */
.socialmedia-button {
  display: flex;
  margin: 10px 0px;
  width: 100%;
}

.socialmedia-button.pagenumbers {
  width: unset;
}

.socialmedia-button button,
.socialmedia-button a {
  width: 100%;
  text-align: center;
  color: var(--heading-color);
  padding: 8px 65px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  min-width: 233px;
  border: solid 1.5px var(--second-color);
  background-color: #ffffff;
  transition: 0.3s;
}

.socialmedia-button.pagenumbers button {
  width: unset;
  min-width: unset;
  padding: 10px 20px;
  color: var(--first-color);
}

.socialmedia-button button:hover,
.socialmedia-button a:hover {
  /* color: var(--hover-color); */
  color: unset;
  background-color: var(--second-color);
}

.socialmedia-button button img,
.socialmedia-button a img {
  width: 25px;
  margin: 0 8px 0 0;
}

.socialmedia-button.full-width button {
  width: 100%;
  margin: 20px 0;
  color: var(--first-color);
}