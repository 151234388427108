.details-jobs {
  padding: 50px 0;
  background: #cdc8ca1f url(../../../images/clear-path/clear-path-bac.png) center center/cover no-repeat;
  background-position: bottom;
  background-size: contain;
}

.details-jobs .nav {
  background: #fbfafd;
  border-radius: 10px;
  display: flex;
}

.details-jobs .btn-jobs-info img {
  height: 45px;
}

.details-jobs .btn-jobs-info h5,
.details-jobs .body-details h5 {
  color: var(--first-color);
  font-size: 15px;
  font-weight: 600;
}

.details-jobs .btn-jobs-info h5 {
  max-width: 200px;
}

.details-jobs .btn-jobs-info h6,
.details-jobs .btn-jobs-info span,
.details-jobs .body-details .minor-info {
  color: #b6b5b5;
  font-size: 14px;
  margin-bottom: 3px;
  font-weight: 600;
}

.details-jobs .body-details .minor-info span {
  font-weight: 600;
  margin-left: 10px;
}

.details-jobs .job-card {
  cursor: pointer;
  padding: 1rem;
}

.details-jobs .job-card:hover {
  background-color: #ffffff;
}

.details-jobs .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: #fff;
  box-shadow: 3px 3px 20px 0 rgb(0 0 0 / 10%);
  padding: 1rem 1rem 1rem 2rem;
  border-radius: 10px;
  position: relative;
  transition: 0.2s;
}

.details-jobs .nav-pills .nav-link.active::after {
  content: "";
  position: absolute;
  background: #7ef0ac;
  height: 45px;
  width: 3px;
  left: 0;
  top: 20%;
}

.details-jobs .btn-jobs-info .open-btn,
.details-jobs .btn-jobs-info .close-btn,
.details-jobs .body-details .apply-btn {
  position: absolute;
  right: 0 !important;
}

.details-jobs .open-btn button {
  color: #60cc8c;
  border: none;
  background: #82f0ae40;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
  width: 60px;
}

.details-jobs .close-btn button {
  color: #d60000;
  border: none;
  background: #f443363d;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
  width: 60px;
}

/*  */
.details-jobs .body-details .apply-btn {
  background: var(--second-color);
  border: none;
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  color: var(--first-color);
}

.details-jobs .more-info {
  padding: 0;
}

.details-jobs .more-info h6 {
  color: var(--heading-color);
  font-size: 14px;
  margin-bottom: 1rem;
  font-weight: bold;
}

.details-jobs .more-info p {
  color: var(--heading-color);
  font-size: 13px;
  margin-bottom: 2rem;
  white-space: pre-line;
}

.details-jobs .more-info ul li {
  font-size: 13px;
  line-height: 1.5;
}

.details-jobs .more-info .contacts-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.details-jobs .more-info .contacts-info img {
  margin-right: 5px;
}

.details-jobs .more-info .contacts-info .right-margined {
  margin-right: 50px;
}

.details-jobs .tab-content {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

.details-jobs .quarter-3-4 {
  width: 65% !important;
}

.details-jobs .quarter-1-4 {
  width: 33% !important;
}

/* Styling for PathForm************ */
.job-form-container {
  border-radius: 20px;
  background-color: #ffffff;
  max-width: 600px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  color: var(--heading-color);
  max-height: 660px;
  overflow-y: scroll;
}

.job-form-container .job-input {
  border-radius: 5px;
  background-color: #fafafa;
  border: solid 1px #d8d8d8;
  height: 40px;
  /* border-color: var(--heading-color) !important; */
  padding: 10px 5px;
  font-size: 14px;
}

.job-form-container .job-input:focus-visible {
  /* border-style: none; */
  outline: none;
  border: solid 1px var(--first-color);
  background-color: aliceblue;
}

.job-form-container h3 {
  color: var(--first-color);
  margin-bottom: 0;
  font-size: 24px;
}

.job-form-container p {
  font-weight: 500;
  font-size: 14px;
}

.job-form-container .cancel-button {
  width: 100%;
  height: fit-content;
}

.job-form-container .cancel-button .cancel-wrapper {
  width: fit-content;
  float: right;
  cursor: pointer;
}

.job-form-container .cancel-button img {
  display: block;
  height: 20px;
  width: 20px;
}

.job-form-container .cancel-button img:hover {
  opacity: 0.7;
}

.job-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.job-form .uploadfile {
  display: flex;
  flex-direction: column;
}

.job-form .uploadfile .pdfinfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.job-form .uploadfile .pdfinfo .pdficon {
  max-width: 70px;
  display: block;
}

.job-form .uploadfile .pdfinfo .pdfname {
  color: var(--heading-color);
  max-width: 210px;
  overflow-wrap: break-word;
  margin-left: 20px;
}

.job-form .text-input {
  width: 100%;
  margin: 2px 0 15px 0;
  min-height: 70px;
}

.job-form label {
  font-size: 13px;
  margin-top: 7px;
}

.job-form label span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #a1a1a1;
}

.job-form .inputs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px auto;
  justify-content: space-between;
}

.job-form .inputs-wrapper .half-width-cell {
  display: flex;
  flex-direction: column;
  width: 47%;
}

.job-form .inputs-wrapper .half-width-cell .half-input {
  margin: 2px 0px;
}

/* Media */
@media (max-width: 768px) {
  .details-jobs .nav {
    width: 100% !important;
  }

  .details-jobs .tab-content {
    width: 100% !important;
  }

  .details-jobs .more-info span {
    font-size: 12px;
  }
}