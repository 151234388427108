.course-editor {
  padding: 50px 0;
  background: #cdc8ca1f url(../../images/clear-path/clear-path-bac.png) center center/cover no-repeat;
  background-position: bottom;
  background-size: contain;
}

.course-editor .course-content {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  color: var(--heading-color);
}

.course-editor .course-content .course-form {
  display: flex;
  flex-direction: column;
}

.course-editor .course-content .course-form .exam-body {
  padding: 20px;
}

.course-editor .course-content .course-form .exam-body h3 {
  color: var(--first-color);
  text-align: center;
  margin: 20px auto;
}

.course-editor .course-content .course-form .exam-body .demo-wrapper {
  background-color: #f7f2ff;
  border-radius: 10px;
}

.course-editor .course-content .course-form .exam-body .demo-editor {
  padding: 0 20px;
  min-height: 200px;
}

.course-editor .course-content .course-form h4 {
  color: var(--first-color);
  font-weight: 600;
  margin-bottom: 0px;
}

.course-editor .course-content .course-form .row {
  padding: 20px 0;
  /* border-bottom: 1px solid rgba(106, 106, 106, 0.2); */
}

.course-editor .course-content .course-form .row.no-padding {
  padding: 5px 0;
}

/* .course-editor .course-content .course-form .row.noborder {
  border-bottom: unset;
} */

.course-editor .course-content .course_input {
  border-radius: 5px;
  background-color: #fafafa;
  border: none;
  height: 45px;
  padding: 5px 10px;
  font-size: 14px;
  color: var(--heading-color);
  border: 1px solid rgba(106, 106, 106, 0.2);
}

/* .course-editor .course-content .course_input.nowidth {
  width: unset;
} */
.course-editor .course-content textarea {
  min-height: 70px;
}

.course-editor .course-content .form-select {
  background-image: url(../../images/arrow/arrow-2.png);
  background-size: 17px 10px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  box-shadow: none !important;
  /* margin: 20px auto 5px; */
}

.course-editor .course-content .form-select:hover {
  cursor: pointer;
}

.course-editor .course-content .course_input:focus-visible {
  outline: none;
  border: solid 1px var(--first-color);
  font-weight: 500;
  color: black;
  background-color: rgba(126, 240, 172, 0.2);
}

.course-editor label {
  margin-bottom: 3px;
  font-size: 15px;
  text-transform: capitalize;
}

.course-editor label span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #a1a1a1;
}

.course-editor label.label-question {
  font-weight: bold;
  margin-top: unset;
  margin-bottom: unset;
}

.course-editor .course-content .actions-section {
  padding: 20px 0px;
}

.course-editor .course-content .sentences p i {
  color: var(--cancel-color);
  margin-left: 5px;
  cursor: pointer;
}

.course-editor .course-content .sentences p i:hover {
  opacity: 0.7;
}


.course-editor .course-content .editing-section-header {
  flex-flow: row;
  display: flex;
  padding: 10px 0px;
  color: var(--first-color);
  background-color: var(--tag-background);
  margin: 30px 0px;
  border-radius: 5px;
  align-items: center;
}

.course-editor .course-content .editing-section-header .section-title {
  padding: 5px 10px;
}

.course-editor .course-content .editing-section-header .section-button {
  background-color: var(--second-color);
  padding: 10px 30px;
  border: none;
  border-radius: 10px;
  transition: 0.3s;
  font-weight: bold;
  color: var(--first-color);
  margin-left: 50px;
}

.course-editor .course-content .editing-section-header .section-button:hover {
  opacity: 0.8;
}

.course-editor .course-content .btn-outline-secondary {
  background-color: var(--second-color);
  color: var(--first-color);
}

.course-editor .course-content .btn-outline-secondary:hover {
  opacity: 0.8;
  background-color: unset !important;
}

.imageLimitInfo {
  margin-bottom: 10px;
}

.imageLimitInfo h6 {
  margin: unset;
  font-style: italic;
  font-size: small;
  color: #0145ac;
}

/* *************Course lessons editing section************* */
.course-lessons-wrapper {
  width: 100%;
  background-color: rgba(192, 192, 192, 0.2);
  margin: 20px 0;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

.course-lessons-wrapper .input-group {
  padding: 10px;
  background-color: rgba(192, 192, 192, 0.2);
}

.course-lessons-wrapper .droppingElem {
  width: 75%;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(126, 240, 172, 0.4);
  color: var(--first-color);
  min-height: 70px;
}

.course-lessons-wrapper .droppingElem.active {
  background-color: rgb(126, 240, 172, 0.1);
}

.course-lessons-wrapper .draggingElem {
  background-color: #ffffff;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.course-lessons-wrapper .draggingElem.active {
  background-color: var(--second-color)
}

.course-lessons-wrapper .draggingElem .dragging-title {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.course-lessons-wrapper .draggingElem i {
  margin-right: 10px;
  cursor: pointer;
  font-size: 22px;
}

.course-lessons-wrapper .draggingElem i:hover {
  opacity: 0.7;
}

/* *************Lesson Details Modal************* */
.lessondetail-form-container {
  border-radius: 40px;
  background-color: #ffffff;
  max-width: 800px;
  min-width: 600px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  color: var(--heading-color);
}

.lessondetail-form-container h3 {
  color: var(--first-color);
  margin-bottom: 10px;
}

.lessondetail-form-container p {
  font-weight: 500;
  font-size: 14px;
}

.lessondetail-form-container .cancel-button {
  width: 100%;
  height: fit-content;
}

.lessondetail-form-container .cancel-button .cancel-wrapper {
  width: fit-content;
  float: right;
  cursor: pointer;
}

.lessondetail-form-container .cancel-button img {
  display: block;
  height: 20px;
  width: 20px;
}

.lessondetail-form-container .cancel-button img:hover {
  opacity: 0.7;
}

.lessondetail-form-container .lesson-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.lessondetail-form-container .lesson-form label {
  font-size: 13px;
}

.lessondetail-form-container .inputs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px auto;
}

.lessondetail-form-container .inputs-wrapper .half-width-cell {
  display: flex;
  flex-direction: column;
  width: 45%;
  align-self: flex-end;
  margin-right: 20px;
}

.video-server-selection {
  display: flex;
  height: 45px;
  padding: 7px 20px;
  border-radius: 5px;
  width: fit-content !important;
  color: var(--first-color);
  font-weight: 600;
  border: 1px solid var(--first-color);
}


/* *************list all courses section************* */
.list-courses--wrapper {
  width: 100%;
  background-color: #ffffff;
  border: thin solid rgb(190, 190, 190);
  padding: 10px;
  border-radius: 10px;
}

/* ***************list all users******************* */

.admin-edit-users {
  display: flex;
  color: #FF3A46;
}

.admin-edit-users i {
  cursor: pointer;
  padding: 7px;
  float: left;
  transition: 0.3s
}

.admin-edit-users i:hover {
  opacity: 0.6;
}

/* ***************edit user details - userdetails-editor******************* */
.userdetails-editor {
  padding: 50px 0;
  background: #cdc8ca1f url(../../images/clear-path/clear-path-bac.png) center center/cover no-repeat;
  background-position: bottom;
  background-size: contain;
}

.userdetails-editor .useredit-content {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  color: var(--heading-color);
  overflow-x: scroll;
}

.userdetails-editor .useredit-content h4 {
  margin-top: 20px;
}

.userdetails-editor .useredit-content .enrolled-courses {
  background-color: #f2ecff;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 10px;
}

.userdetails-editor .useredit-content .useredit-form {
  display: flex;
  flex-direction: column;
}

.userdetails-editor .useredit-content .article-body {
  padding: 20px;
}

.userdetails-editor .useredit-content .article-body h3 {
  color: var(--first-color);
  text-align: center;
  margin: 20px auto;
}

.userdetails-editor .useredit-content .article-body .demo-wrapper {
  background-color: #f7f2ff;
  border-radius: 10px;
}

.userdetails-editor .useredit-content .article-body .demo-editor {
  padding: 0 20px;
  min-height: 200px;
}

.userdetails-editor .useredit-content .useredit-form .border-bottom {
  padding: 20px 0;
  margin-bottom: 30px;
  border-bottom: 2px double rgba(106, 106, 106, 0.2);
}

.userdetails-editor .useredit-content .useredit-form .row.noborder {
  border-bottom: unset;
}

.userdetails-editor .useredit-content .highlighted-title {
  font-size: 18px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--first-color);
  background-color: var(--tag-background);
  width: fit-content;
  margin-top: 10px;
}

.userdetails-editor .useredit-content .useredit_input {
  border-radius: 5px;
  background-color: #fafafa;
  border: none;
  height: 45px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  color: var(--heading-color);
  border: 1px solid rgba(106, 106, 106, 0.2);
  width: 100%;
}

.userdetails-editor .useredit-content .useredit_input.limit-width {
  width: 160px;
}

.userdetails-editor .useredit-content .useredit_input.nowidth {
  width: unset;
}

.userdetails-editor .useredit-content textarea {
  min-height: 70px;
}

.userdetails-editor .useredit-content .form-select {
  background-image: url(../../images/arrow/arrow-2.png);
  background-size: 17px 10px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  box-shadow: none !important;
  /* margin: 20px auto 5px; */
}

.userdetails-editor .useredit-content .form-select:hover {
  cursor: pointer;
}

.userdetails-editor .useredit-content .useredit_input:focus-visible {
  outline: none;
  border: solid 1px var(--first-color);
  background-color: rgba(126, 240, 172, 0.2);
}

.userdetails-editor .useredit-content label {
  /* margin-top: 15px; */
  margin-bottom: 3px;
  font-size: 15px;
}

.userdetails-editor .useredit-content .actions-section {
  padding: 20px 0px;
}

.userdetails-editor .useredit-content .skills p i {
  color: var(--cancel-color);
  margin-left: 5px;
  cursor: pointer;
}

.userdetails-editor .useredit-content .skills p i:hover {
  opacity: 0.7;
}

.userdetails-editor .buttons_wrapper {
  margin: 40px 0px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}