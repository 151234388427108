.course-stripes-container {
  width: 100%;
  /* background-color: aliceblue; */
  overflow-x: auto;
  padding-bottom: 40px;
  color: var(--heading-color);
}
.course-stripes-container .items-header {
  border-top: thick double rgb(190, 190, 190);
  border-bottom: thick double rgb(190, 190, 190);
  padding: 20px 0px 10px;
  display: flex;
  width: 100%;
  min-width: 800px;
}

.course-stripes-container .items-header .items1 {
  width: 55%;
  padding-left: 5px;
  text-align: center;
}
.course-stripes-container .items-header .items2 {
  width: 20%;
  padding-left: 20px;
}
.course-stripes-container .items-header .items3 {
  width: 15%;
  padding-left: 20px;
}

.course-stripes-container .course-stripe {
  height: 120px;
  width: 100%;
  min-width: 800px;
  padding: 30px 10px;
  transition: 0.3s;
  font-weight: 700;
}
.course-stripes-container .course-stripe.order-stripe {
  min-width: 500px;
}
.course-stripes-container .course-stripe:hover {
  box-shadow: 5px 5px 20px 0 rgb(0 0 0 / 10%);
}
.course-stripes-container .course-stripe .image-wrapper{
  float: left;
  width: 15%;
  height: 100%;
}
.course-stripes-container .course-stripe .image-wrapper img{
  display: block;
  top: 0px;
  margin: auto;
  width: 80%;
}
.course-stripes-container .course-stripe .course-details-stripe{
  float: left;
  width: 40%;
  height: 100%;
  padding: 0px 30px;
}
.course-stripes-container .course-stripe .course-details-stripe h6 {
  font-size: 14px;
}
.course-stripes-container .course-stripe .course-details-stripe .price-rate{
  color: var(--heading-color);
  display: flex;
}
.course-stripes-container .course-stripe .course-details-stripe .price-rate del {
  margin-right: 7px;
  color: rgb(179, 179, 179);
}
.course-stripes-container .course-stripe .course-details-stripe .price-rate span{
  margin: 0px 0px 0px 15px;
}

.course-stripes-container .course-stripe .course-details-stripe .price-rate .rating{
  float: right;
  color: gold;
}
.course-stripes-container .course-stripe .course-details-stripe .price-rate .rating ul{
  padding-left: 5px !important;
}
.course-stripes-container .course-stripe .course-main-category{
  float: left;
  width: 25%;
  height: 100%;
  font-size: small;
  font-weight: 600;
}
.course-stripes-container .course-stripe .course-main-category p{
  background-color: var(--tag-background);
  color: var(--first-color);
  padding: 10px;
  width: fit-content;
  border-radius: 10px;
}
.course-stripes-container .course-stripe .certification{
  height: 100%;
  float: left;
  width: 10%;
  text-align: center;
  font-size: medium;
  padding-top: 7px;
}
.course-stripes-container .course-stripe .certification.order-price {
  text-align: right;
  padding-top: 0px;
}
.course-stripes-container .course-stripe .remove-course{
  float: left;
  width: 10%;
  height: 100%;
  color: #FF3A46;
  font-size: 25px;
}
.course-stripes-container .course-stripe .remove-course i {
  font-size: 20px;
  cursor: pointer;
  padding: 7px;
  float: left;
  transition: 0.3s;
}
.course-stripes-container .course-stripe .remove-course i:hover {
  opacity: 0.6;
}