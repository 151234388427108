/* Strat Matching Freelancers page top banner style */
.banner-section {
  position: relative;
  min-height: 60vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.banner-section img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -o-object-fit: cover;
      object-fit: cover;
  -o-object-position: center;
      object-position: center;
  z-index: -2;
  pointer-events: none;
}
.banner-section h4, .banner-section h2, .banner-section h3 {
  color: white;
}
.banner-section p {
  color: var(--second-color);
}
.banner-section::after {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  /* background: rgba(0, 0, 0, 0.6) !important; */
}
.z-index-bannertext {
  z-index: 3;
}
@media (max-width: 996px) {
  .font-mobile {
    font-size: 20px;
}
}
/* End Matching Freelancers page top banner style */