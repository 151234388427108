.footer {
  background: url(../../images/footer/footer.webp) center center/cover no-repeat;
  position: relative;
  padding: 100px 0 10px 0;
}

.footer ul li {
  margin-bottom: 20px;
}

.footer ul li a {
  color: #fff;
  text-transform: capitalize;
  font-size: 14px;
}

.footer .logo-footer {
  max-width: 200px;
}

.footer .container .copy-right p {
  margin-top: 10px;
  font-size: 14px;
  color: var(--second-color);
}