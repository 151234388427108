.cart-section {
  padding: 80px 20px;
  width: 100%;
  color: var(--heading-color);
}
.add-courses-button {
  display: flex;
  margin: 20px 0;
}
.cart-section .userdetails-order {
  width: 100%;
  border-bottom: thin solid rgb(190, 190, 190);
  padding: 10px;
  margin-bottom: 20px;
}
.cart-section .userdetails-order .userdetails {
  width: 100%;
  padding-bottom: 20px;
  font-weight: bold;
}
.cart-section .userdetails-order .userdetails span {
  margin-left: 90px;
  font-weight: normal;
}
.cart-section .order-container {
  width: 100%;
  background-color: aliceblue;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
.final-order-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  background-color: var(--tag-background);
}
.payment-order {
  margin-top: 20px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
}
.payment-order .paypal-buttons {
  height: 130px !important;
}
/* .card-form .cardsecurity-field {
  width: 40%;
  display: flex;
  flex-direction: column;
} */
.cart-section .cart-summary {
  width: 100%;
  background-color: #ffffff;
  border: thin solid rgb(190, 190, 190);
  padding: 10px;
  border-radius: 10px;
}
.cart-section .cart-summary .summary-cell{
  width: 100%;
  padding: 15px 0px;
  border-bottom: thin solid rgb(190, 190, 190);
  text-align: left;
}
.cart-section .cart-summary .summary-total{
  width: 100%;
  padding: 30px 0px 15px 0px;
  border-top: 4px double rgb(190, 190, 190);
  text-align: left;
}
.cart-section .cart-summary .summary-cell span, .cart-section .cart-summary .summary-total span {
  float: right;
  font-weight: bold;
}
.cart-section .discount {
  background-color: aliceblue;
  margin-top: 10px;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
}

/* Congratulation PopUp */
.order-congratulate-popup {
  border-radius: 40px;
  background-color: #ffffff;
  max-width: 600px;
  margin: 80px auto;
  padding: 20px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  color: var(--heading-color);
}
.order-congratulate-popup h5 {
  color: var(--first-color);
  margin-bottom: 20px;
}
.order-congratulate-popup p {
  font-weight: 500;
}
/* No Cart Courses */
.no-cart-courses {
  border-radius: 40px;
  background-color: #ffffff;
  max-width: 600px;
  margin: 80px auto;
  padding: 20px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  color: var(--heading-color);
}
.no-cart-courses h4 {
  color: var(--first-color);
  margin: 10px 0 40px 0;
}
.no-cart-courses .cancel-button {
  width: 100%;
  height: fit-content;
}
.no-cart-courses .cancel-button .cancel-wrapper {
  width: fit-content;
  float: right;
  cursor: pointer;
}
.no-cart-courses .cancel-button img {
  display: block;
  height: 20px;
  width: 20px;
}
.no-cart-courses .cancel-button img:hover {
  opacity: 0.7;
}