.page-under-construction {
  width: 100%;
  color: var(--heading-color);
  text-align: center;
  font-weight: 600;
  min-height: 600px;
  display: flex;
 }
 .construction-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 80%;
 }
 .page-under-construction img {
   display: block;
   margin: auto;
   width: 200px;
   margin-bottom: 40px;
 }