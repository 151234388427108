.card-form {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:space-between;
  font-size: 13px;
}
.card-form input {
  background-color: rgb(250, 250, 250);
  border: solid 1px #d8d8d8;
  padding: 10px;
  border-radius: 5px;
}
.card-form input:focus-visible {
  outline: none;
  border: solid 1px var(--first-color) !important;
  background-color: aliceblue;
}
.card-form .cardfield {
  flex-direction: column;
  display: flex;
  margin: 20px 0px;
}
.card-form .cardfield label {
  color: var(--heading-color);
  margin-bottom: 5px;
  font-weight: 600;
}
.card-form .cardname-field {
  width: 100%;
}
.card-form .cardexpiry-field {
  width: 55%;
}
.card-form .cardsecurity-field {
  width: 35%;
}
.card-form .cardexpiry-field .expiry-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.card-form .cardexpiry-field .expiry-container input {
  width: 45%;
  margin-right: 10px;
}