
.details-learnPaths {
  padding: 50px 0;
  background: #cdc8ca1f url(../../images/clear-path/clear-path-bac.png)
    center center/cover no-repeat;
  background-position: bottom;
  background-size: contain;
}
.details-learnPaths .quarter-3-4 {
  width: 65% !important;
}
.details-learnPaths .quarter-1-4 {
  width: 30% !important;
}

/* Selector Section********************************** */
.details-learnPaths .nav {
  background: #fbfafd;
  display: flex;
  border-radius: 10px;
}
.details-learnPaths .path-card {
  cursor: pointer;
  border-radius: 10px !important;
  position: relative;
}
.details-learnPaths .path-card:hover {
  background-color: #ffffff;
}
.details-learnPaths .path-card h5 {
  color: var(--first-color);
  margin-bottom: 1rem;
  font-size: 18px;
  text-transform: capitalize;
}
.details-learnPaths .path-instruct-wrap {
  margin: 20px 0;
  padding: 36px 40px;
  border-radius: 10px;
  color: var(--heading-color);
  background-color: #f2ecff;
}
.details-learnPaths .btn-paths-info img {
  height: 45px;
  border-radius: 50%;
}
.details-learnPaths .body-details h5 {
  color: var(--first-color);
  font-size: 18px;
  font-weight: 600;
}
.details-learnPaths .btn-paths-info h6 {
  color: #6d6d6d;
  font-size: 15px;
  font-weight: 600;
}
.details-learnPaths .btn-paths-info span,
.details-learnPaths .body-details span {
  color: #b6b5b5;
  font-size: 13px;
}

.details-learnPaths .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #fff;
  box-shadow: 3px 3px 20px 0 rgb(0 0 0 / 10%);
  padding: 0.5rem 2rem;
  border-radius: 10px;
  /* position: relative; */
  transition: 0.2s;
}
.details-learnPaths .nav-pills .nav-link.active::after {
  content: "";
  position: absolute;
  background: #7ef0ac;
  height: 40%;
  width: 5px;
  left: 0;
  top: 15%;
}
.details-learnPaths .language-tag {
  position: absolute;
  right: 10px !important;
  top: 10px !important;
  color: #60cc8c;
  border: none;
  background: #82f0ae40;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
}
.details-learnPaths .language-tag i {
  margin-right: 10px;
}

/* Screen Section********************************** */
.details-learnPaths .tab-content {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}
.details-learnPaths .body-details .apply-btn {
  background: var(--second-color);
  border: none;
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  color: var(--first-color);
  position: absolute;
  right: 0 !important;
}

/* .details-learnPaths .more-info {
  padding: 0;
}

.details-learnPaths .more-info h6 {
  color: var(--heading-color);
  font-size: 16px;
}
.details-learnPaths .more-info p {
  color: var(--heading-color);
  font-size: 13px;
} */
.details-learnPaths .more-info .article {
  background: #fff;
  border-radius: 10px;
  padding: 30px 10px;
  margin: 50px 0px;
  border: 1px solid var(--tag-background);
}
.details-learnPaths .more-info ul, .details-learnPaths .more-info ol {
  position: relative;
}
.details-learnPaths .more-info ul li, .details-learnPaths .more-info ol li {
  font-size: 13px;
  line-height: 1.5;
  color: var(--heading-color);
  margin-left: 28px;
  margin-bottom: 13px;
}
.details-learnPaths .more-info ul li::before {
  content: none;
}
.details-learnPaths .more-info .contacts-info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.details-learnPaths .more-info .contacts-info img {
  margin-right: 5px;
}
.details-learnPaths .more-info .contacts-info .right-margined {
  margin-right: 50px;
}
.details-learnPaths .more-info .statistics-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  color: var(--heading-color);
  font-weight: 500;
}
.details-learnPaths .more-info .statistics-info p {
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
}
.details-learnPaths .more-info .statistics-info p::before {
  content: "";
  position: relative;
  display: flex;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-right: 15px;
}
.details-learnPaths .more-info .statistics-info .job::before {
  background-color: var(--second-color);
}
.details-learnPaths .more-info .statistics-info .startB::before {
  background-color: var(--first-color);
}
.details-learnPaths .more-info .statistics-container {
  border-radius: 10px;
  background-color: #f2ecff;
  --bs-gutter-x: none;
}

/* Styling for PathForm************ */
.path-form-container {
  border-radius: 40px;
  background-color: #ffffff;
  max-width: 650px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  color: var(--heading-color);
}
.path-form-container .path-input {
  border-radius: 5px;
  background-color: #fafafa;
  border: solid 1px #d8d8d8;
  height: 40px;
  /* border-color: var(--heading-color) !important; */
  padding: 10px 5px;
  font-size: 14px;
}
.path-form-container .path-input:focus-visible {
  /* border-style: none; */
  outline: none;
  border: solid 1px var(--first-color);
  background-color: aliceblue;
}
.path-form-container h3 {
  color: var(--first-color);
  margin-bottom: 10px;
}
.path-form-container p {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: unset;
}
.path-form-container .cancel-button {
  width: 100%;
  height: fit-content;
}
.path-form-container .cancel-button .cancel-wrapper {
  width: fit-content;
  float: right;
  cursor: pointer;
}
.path-form-container .cancel-button img {
  display: block;
  height: 20px;
  width: 20px;
}
.path-form-container .cancel-button img:hover {
  opacity: 0.7;
}

.path-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.path-form .text-input {
  width: 100%;
  margin: 2px 0 15px 0;
  min-height: 70px;
}
.path-form label {
  font-size: 13px;
  margin-top: 7px;
}
.path-form label span {
  margin-left: 10px;
  font-size: 11px;
  color: #a1a1a1;
}

.path-form .inputs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px auto;
  justify-content: space-between;
}
.path-form .inputs-wrapper .half-width-cell {
  display: flex;
  flex-direction: column;
  width: 47%;
}
.path-form .inputs-wrapper .half-width-cell .half-input {
  margin: 2px 0px;
}
.learnpath-model {
  padding: 50px;
  background-color: #fbfafd;
  text-align: center;
}
.learnpath-model h3 {
  color: var(--first-color);
  text-transform: capitalize;
}
.learnpath-model img {
  display: flex;
  margin: auto;
  max-width: 700px;
  min-width: 250px;
}

/* Media */
@media (max-width: 768px) {
  .details-learnPaths .nav {
    width: 100% !important;
  }
  .details-learnPaths .tab-content {
    width: 100% !important;
  }

  .details-learnPaths .more-info span {
    font-size: 12px;
  }
}
