.instructor-list-courses {
  padding: 80px 20px;
  width: 100%;
}
.courselist-placeholder {
  width: 100%;
  padding: 20px 0px;
  display: flex;
}
/* .courselist-placeholder h4 {
  width: fit-content;
  float: left;
} */
.courselist-createcourse {
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.courselist-createcourse h4 {
  width: fit-content;
  float: left;
  margin-bottom: 0px;
}
/* .courselist-createcourse button {
  float: right;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
  background-color: #1b1d3d;
  color: #fff;
  text-transform: uppercase;
  transition: 0.3s;
}
.courselist-createcourse button:hover {
  opacity: 0.8;
} */