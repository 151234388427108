/* Styling for all Modals that open in user profile page************ */
.profile-modal-container {
  border-radius: 20px;
  background-color: #ffffff;
  min-width: 600px;
  max-width: 700px;
  max-height: 900px;
  overflow-y: scroll;
  padding: 30px;
  display: flex;
  flex-direction: column;
  color: var(--heading-color);
}
.profile-modal-container h3 {
  color: var(--first-color);
  margin-bottom: 10px;
}
.profile-modal-container p {
  font-weight: 500;
  font-size: 14px;
}
.profile-modal-container .cancel-button {
  width: 100%;
  height: fit-content;
}
.profile-modal-container .cancel-button .cancel-wrapper {
  width: fit-content;
  float: right;
  cursor: pointer;
}
.profile-modal-container .cancel-button img {
  display: block;
  height: 17px;
  width: 17px;
}
.profile-modal-container .cancel-button img:hover {
  opacity: 0.7;
}
.profile-modal-container label {
  margin-top: 15px;
  margin-bottom: 3px;
  font-size: 14px;
}

/* Form for editing user information */
.edit-userinfo-form {
  padding: 10px 30px;
}
.edit-userinfo-form .form {
  display: flex;
  flex-direction: column;
}
.delete-confirm-modal {
  padding: 10px 50px;
}
.delete-confirm-modal .half-width {
  width: 47%;
}
.delete-confirm-modal .half-width button {
  width: 100% !important;
  padding: 10px 30px;
}