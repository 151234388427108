.jobs-editor {
  padding: 50px 0;
  background: #cdc8ca1f url(../../images/clear-path/clear-path-bac.png) center center/cover no-repeat;
  background-position: bottom;
  background-size: contain;
}

.jobs-editor .job-content {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  color: var(--heading-color);
}

.jobs-editor .job-content .job-form {
  display: flex;
  flex-direction: column;
}

.jobs-editor .job-content .job-form .personal-photo {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.jobs-editor .job-content .job-form .photo-wrapper {
  width: fit-content;
  margin: auto;
  position: relative;
  padding: 10px;
}

.jobs-editor .job-content .job-form .photo-wrapper.dragover {
  opacity: 0.6;
  border: 3px dotted var(--heading-color);
  border-radius: 10px;
}

.jobs-editor .job-content .job-form .photo-wrapper .user-img {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: auto;
}

.jobs-editor .job-content .job-form .photo-wrapper .edit-icn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: fit-content;
}

.jobs-editor .job-content .job-form .photo-wrapper .edit-icn img {
  width: 40px;
  transition: 0.2s;
  cursor: pointer;
}

.jobs-editor .job-content .job-form .photo-wrapper .edit-icn img:hover {
  width: 50px;
  opacity: 0.8;
}

.jobs-editor .job-content .job-form .photo-wrapper .edit-icn .upload-input {
  display: none;
}

.jobs-editor .job-content .job-form h4 {
  color: var(--first-color);
  font-weight: 600;
  margin-bottom: 0px;
}

.jobs-editor .job-content .job-form .row {
  padding: 20px 0;
  /* border-bottom: 1px solid rgba(106, 106, 106, 0.2); */
}

/* .jobs-editor .job-content .job-form .row.noborder {
  border-bottom: unset;
} */
.jobs-editor .job-content .highlighted-title {
  font-size: 18px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--first-color);
  background-color: var(--tag-background);
  width: fit-content;
  margin-top: 10px;
}

.jobs-editor .job-content .job_input {
  border-radius: 5px;
  background-color: #fafafa;
  border: none;
  height: 45px;
  padding: 5px 10px;
  font-size: 14px;
  color: var(--heading-color);
  border: 1px solid rgba(106, 106, 106, 0.2);
  width: 100%;
}

.jobs-editor .job-content .job_input.nowidth {
  width: unset;
}

.jobs-editor .job-content textarea {
  min-height: 70px;
}

.jobs-editor .job-content .form-select {
  background-image: url(../../images/arrow/arrow-2.png);
  background-size: 17px 10px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  box-shadow: none !important;
  /* margin: 20px auto 5px; */
}

.jobs-editor .job-content .form-select:hover {
  cursor: pointer;
}

.jobs-editor .job-content .job_input:focus-visible {
  outline: none;
  border: solid 1px var(--first-color);
  font-weight: 500;
  color: black;
  background-color: rgba(126, 240, 172, 0.2);
}

.jobs-editor .job-content label {
  margin-top: 15px;
  margin-bottom: 3px;
  font-size: 15px;
}

.jobs-editor .job-content .actions-section {
  padding: 20px 0px;
}

.jobs-editor .job-content .sentences p i {
  color: var(--cancel-color);
  margin-left: 5px;
  cursor: pointer;
}

.jobs-editor .job-content .sentences p i:hover {
  opacity: 0.7;
}