.application_stripe-header {
  margin: 15px 0;
  color: var(--heading-color);
}

.application-wrap .row-tags .tag {
  font-weight: 500 !important;
  font-size: 13px !important;
  padding: 5px 10px;
}

.application-wrap .row-tags .tag i {
  color: #fdc202 !important;
  margin-left: 7px;
}

.application-wrap .row-tags .tag span {
  color: #fdc202 !important;
}

.pdf-container {
  display: flex;
  width: 94%;
  cursor: pointer;
  align-items: center;
  padding: 7px 0;
  border-top: 2px solid #dbdbdb;
}

.pdf-container .pdffile {
  display: block;
  max-width: 40px;
}

.pdf-container .pdfname {
  margin-left: 15px;
  overflow-wrap: break-word;
  max-width: 200px;
  color: var(--first-color);
  font-size: 12px;
  margin-bottom: 0;
}

.application_stripe-header .img-container {
  height: 50px;
  width: 50px;
  position: relative;
  cursor: pointer;
}

.application_stripe-header .img-container:hover .linkedin {
  width: 35px;
  opacity: 0.8;
}

.application_stripe-header .profile {
  border-radius: 50%;
  width: 100%;
}

.application_stripe-header .linkedin {
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  right: -5px;
  bottom: -5px;
  width: 25px;
  transition: 0.2s;
}

.application_stripe-header .text-start h6 {
  color: #6d6d6d;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 3px !important;
}

.application_stripe-header .text-start p {
  color: #b6b5b5;
  font-size: 13px;
  margin-bottom: unset !important;
}

.application_stripe-body {
  margin: 5px;
  font-size: 14px;
  font-weight: 500;
  color: var(--heading-color);
  white-space: pre-line;
}