/* Instructor styling */
.member-card {
  padding: 30px 5px;
  border-radius: 20px;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
.member-card:hover {
  cursor: pointer;
  background-color: var(--page-background);
}
.member-card:hover h5 {
  color: var(--first-color);
}
.member-card .imgs-wrapper {
  display: flex;
  width: 150px;
  margin: 0px auto;
  height: 150px;
  position: relative;
}
.member-card .imgs-wrapper img {
  border-radius: 50%;
}
.member-card .imgs-wrapper .memberImg {
  width: 100%;
  height: 100%;
}
.member-card .imgs-wrapper .linkedinicon {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 43px;
  height: 43px;
}
.member-card .card-title {
  color: var(--heading-color);
  font-weight: 600;
  font-size: 17px;
}
.member-card .card-text {
  color: var(--heading-color);
  font-size: 14px;
  line-height: 1.8;
}
