.pricing-area {
  padding: 70px 0;
  color: var(--heading-color);
}

.pricing-area .section_head {
  padding: 20px 0;
  margin-bottom: 30px;
  text-align: center;
}

.pricing-area .section_head .title {
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--first-color);
}

.pricing-area .section_head .shortnotice {
  /* text-decoration: underline; */
  color: orange;
  font-size: 12px;
  padding: 5px 10px;
  background-color: #fff6e6;
  border-radius: 10px;
}

.pricing-area .card_container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid var(--heading-color-light);
  border-radius: 17px;
  box-shadow: 0px 0px 1px 0 #aeaeae;
  transition: 0.3s;
  height: 745px;
  position: relative;
  align-items: center;
}

.pricing-area .card_container .card-footer {
  position: absolute;
  bottom: 15px;
  width: 85%;
}

.pricing-area .card_container .card-footer .trial {
  font-size: 13px;
  font-style: italic;
  color: #9d9d9d;
  font-weight: 600;
  text-align: center;
}

.pricing-area .card_container:hover {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
}

.pricing-area .card_container .card_body li {
  font-size: 14px;
}

.pricing-area .card_container .card_body .styled-help-info i:hover {
  position: unset !important;
}

.pricing-area .card_container .card_body .styled-help-info i:hover span {
  line-height: 1.6;
  font-weight: 500;
  width: unset !important;
  top: unset !important;
}

.pricing-area .card_container .recommend {
  position: absolute;
  top: -40px;
  height: 60px;
  width: 80%;
  left: 10%;
  font-size: 14px;
  text-align: center;
  background-color: var(--first-color-light);
  border-radius: 7px;
  color: var(--first-color);
  font-weight: 600;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.pricing-area .card_container .card_header {
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid var(--heading-color-light);
  margin-bottom: 17px;
  width: 93%;
  min-height: 150px;
}

.pricing-area .card_container .card_header .pricing_title {
  height: 75px;
}

.pricing-area .card_container .card_header .price {
  margin: 5px 0px 0px 0px;
}

.pricing-area .card_container .card_header .price .separator {
  margin: auto 7px;
  color: var(--heading-color);
  font-size: 20px;
  background-color: rgb(245, 245, 245);
  border-radius: 10px;
  padding: 10px;
}

.pricing-area .card_container .card_header .price .temp {
  text-decoration: line-through;
  color: #8d8d8d;
  margin-right: 7px;
}

.pricing-area .card_container .card_header .period {
  font-size: 12px;
  color: var(--heading-color);
  font-weight: 500;
}

.pricing-area .form_container {
  border-radius: 40px;
  background-color: #ffffff;
  max-width: 600px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  color: var(--heading-color);
}

.pricing-area .form_container .cancel-button {
  width: 100%;
  height: fit-content;
}

.pricing-area .form_container .cancel-button .cancel-wrapper {
  width: fit-content;
  float: right;
  cursor: pointer;
}

.pricing-area .form_container .cancel-button img {
  display: block;
  height: 20px;
  width: 20px;
}

.pricing-area .form_container .cancel-button img:hover {
  opacity: 0.7;
}