.clear-path {
  background: #cdc8ca1f url(../../../images/clear-path/clear-path-bac.png) center center/cover no-repeat;
}

.clear-path .row .mission_img {
  max-width: 400px;
  float: left;
}

.clear-path h2 {
  color: var(--first-color);
  font-weight: 600;
}

.clear-path p {
  color: var(--heading-color);
  line-height: 1.8;
}