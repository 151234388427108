.learnpaths-editor {
  padding: 50px 0;
  background: #cdc8ca1f url(../../images/clear-path/clear-path-bac.png)
    center center/cover no-repeat;
  background-position: bottom;
  background-size: contain;
}
.learnpaths-editor .path-content {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  color: var(--heading-color);
}
.learnpaths-editor .path-content .path-form {
  display: flex;
  flex-direction: column;
}
.learnpaths-editor .path-content .article-body {
  padding: 20px;
}
.learnpaths-editor .path-content .article-body h3 {
  color: var(--first-color);
  text-align: center;
  margin: 20px auto;
}
.learnpaths-editor .path-content .article-body .demo-wrapper {
  background-color: #f7f2ff;
  border-radius: 10px;
}
.learnpaths-editor .path-content .article-body .demo-editor {
  padding: 0 20px;
  min-height: 200px;
}
.learnpaths-editor .path-content .path-form .row {
  padding: 20px 0;
  border-bottom: 1px solid rgba(106, 106, 106, 0.2);
}
.learnpaths-editor .path-content .path-form .row.noborder {
  border-bottom: unset;
}
.learnpaths-editor .path-content .highlighted-title {
  font-size: 18px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--first-color);
  background-color: var(--tag-background);
  width: fit-content;
  margin-top: 10px;
}
.learnpaths-editor .path-content .path_input {
  border-radius: 5px;
  background-color: #fafafa;
  border: none;
  height: 45px;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  color: var(--heading-color);
  border: 1px solid rgba(106, 106, 106, 0.2);
  width: 100%;
}
.learnpaths-editor .path-content .path_input.nowidth {
  width: unset;
}
.learnpaths-editor .path-content textarea {
  min-height: 70px;
}
.learnpaths-editor .path-content .form-select {
  background-image: url(../../images/arrow/arrow-2.png);
  background-size: 17px 10px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  box-shadow: none !important;
  /* margin: 20px auto 5px; */
}
.learnpaths-editor .path-content .form-select:hover {
  cursor: pointer;
}

.learnpaths-editor .path-content .path_input:focus-visible {
  outline: none;
  border: solid 1px var(--first-color);
  background-color: rgba(126, 240, 172, 0.2);
}

.learnpaths-editor .path-content label {
  margin-top: 15px;
  margin-bottom: 3px;
  font-size: 15px;
}

.learnpaths-editor .path-content .actions-section {
  padding: 20px 0px;
}
.learnpaths-editor .path-content .skills p i {
  color: var(--cancel-color);
  margin-left: 5px;
  cursor: pointer;
}
.learnpaths-editor .path-content .skills p i:hover {
  opacity: 0.7;
}