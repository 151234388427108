.profile-container {
  padding: 50px;
  background-color: #fff;
}

.profile-container .columns-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.profile-container .quarter-3-4 {
  width: 75% !important;
}

.profile-container .quarter-1-4 {
  width: 25% !important;
}

.profile-container .nav {
  background: #fbfafd;
  display: flex;
  border-radius: 10px;
  padding: 30px 0px;
}

.profile-container .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: #fff;
  box-shadow: 3px 3px 20px 0 rgb(0 0 0 / 10%);
  padding: 30px 40px;
  border-radius: 10px;
  position: relative;
  transition: 0.3s;
}

.profile-container .nav-pills .nav-link.active::after {
  content: "";
  position: absolute;
  background: #7ef0ac;
  height: 60%;
  width: 5px;
  left: 0;
  top: 25%;
}

.profile-container .panel-card {
  cursor: pointer;
  border-radius: 10px !important;
  padding: 30px 20px;
}

.profile-container .panel-card:hover {
  background-color: #ffffff;
}

.profile-container .panel-card h5 {
  color: var(--first-color);
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 0px;
}

/* panel screen */
.profile-container .panel-content {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
}

/* Common styles for all screens************** */
.profile-container .title-bar-profile {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  width: 100%;
  margin-bottom: 20px;
}

.profile-container .title-bar-profile .sction-title {
  color: var(--first-color);
  font-size: 20px;
}

.profile-container .title-bar-profile .user-action {
  color: #0d6efd;
  cursor: pointer;
}

.profile-container .title-bar-profile .user-action:hover {
  opacity: 0.7;
}

.profile-container .title-bar-profile .user-action i {
  margin-right: 7px;
}

.profile-container .info-body {
  margin: 20px 0px;
}

.profile-container .info-body p {
  margin-bottom: 5px;
  font-size: 14px;
}

/* ******************** main panel screen ***************************** */
.profile-container .panel-content .profile-input {
  border-radius: 5px;
  background-color: #fafafa;
  border: none;
  height: 40px;
  padding: 10px 5px;
  font-weight: 500;
  font-size: 14px;
  color: var(--heading-color);
  width: 100%;
}

.profile-container .panel-content .profile-input.no-padding {
  padding: unset
}

.profile-container .panel-content .form-select {
  background-image: url(../../images/arrow/arrow-2.png);
  background-size: 17px 10px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  box-shadow: none !important;
  /* margin: 20px auto 5px; */
}

.profile-container .panel-content .form-select:hover {
  cursor: pointer;
}

.profile-container .panel-content textarea {
  min-height: 70px;
}

.profile-container .panel-content .profile-input:focus-visible {
  outline: none;
  border: solid 1px var(--first-color);
  background-color: rgba(126, 240, 172, 0.2);
}

.profile-container .panel-content .profile-input .PhoneInputInput {
  border: none;
  background-color: unset;
  color: var(--heading-color);
}

.profile-container .panel-content .profile-input .PhoneInputInput:focus-visible {
  outline: none;
  border: solid 1px var(--first-color);
  background-color: rgba(126, 240, 172, 0.2);
}

.profile-container .panel-content .form-check label {
  margin-top: 0px;
  font-size: 13px;
}

.profile-container .panel-content .form-check label span {
  text-decoration: underline;
  color: var(--first-color);
  font-weight: 500;
  cursor: pointer;
}

.profile-container .panel-content .form-check-input {
  cursor: pointer;
}

.profile-container .panel-content .form-check-input:checked {
  background-color: var(--first-color);
  border-color: var(--first-color);
}

.profile-container .main-profile {
  font-size: 16px;
  width: 100%;
  color: var(--heading-color);
}

.profile-container .main-profile .personal-photo {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.profile-container .main-profile .photo-wrapper {
  width: fit-content;
  margin: auto;
  position: relative;
  padding: 10px;
}

.profile-container .main-profile .photo-wrapper.dragover {
  opacity: 0.6;
  border: 3px dotted var(--heading-color);
  border-radius: 10px;
}

.profile-container .main-profile .photo-wrapper .user-img {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: auto;
}

.profile-container .main-profile .photo-wrapper .edit-icn {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: fit-content;
}

.profile-container .main-profile .photo-wrapper .edit-icn img {
  width: 40px;
  transition: 0.2s;
  cursor: pointer;
}

.profile-container .main-profile .photo-wrapper .edit-icn img:hover {
  width: 50px;
  opacity: 0.8;
}

.profile-container .main-profile .photo-wrapper .edit-icn .upload-input {
  display: none;
}

.main-profile .inputs-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 5px auto;
  justify-content: space-between;
}

.main-profile .inputs-wrapper .half-width-cell {
  display: flex;
  flex-direction: column;
  width: 47%;
}

.main-profile .inputs-wrapper .half-width-cell label {
  margin-top: 20px;
  margin-bottom: 5px;
}

/* ******************** instructor-panel screen ***************************** */
.profile-container .instructor-panel {
  width: 100%;
  display: flex;
  padding: 30px;
  flex-direction: column;
}

.profile-container .instructor-panel .panel-information {
  padding-bottom: 40px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.4);
  margin-bottom: 40px;
  color: var(--heading-color);
}

/* ******************** mentor-panel screen ***************************** */
.profile-container .mentor-panel {
  width: 100%;
  display: flex;
  padding: 30px;
  flex-direction: column;
}

.profile-container .mentor-panel .panel-information {
  padding-bottom: 40px;
  border-bottom: 2px solid rgba(112, 112, 112, 0.4);
  margin-bottom: 40px;
  color: var(--heading-color);
}

.profile-container .mentor-panel .paths-control {
  margin-bottom: 30px;
  border-bottom: 2px solid rgba(112, 112, 112, 0.4);
}


/* *********Styling applied to InstructorInvite screen********************* */
.profile-container .user-invite .body {
  width: 70%;
  position: relative;
  color: var(--heading-color);
}

.profile-container .user-invite .vidinfo-wrap {
  padding: 30px 0;
  border-top: 2px solid #ececec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.profile-container .user-invite .body ul {
  list-style: none;
  margin: 30px 0;
}

.profile-container .user-invite ul li {
  word-spacing: 3px;
  font-weight: 600;
  margin-left: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  text-align: left;
}

.profile-container .user-invite ul li::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0px;
  color: var(--first-color);
}

.profile-container .user-invite .body p {
  font-size: 14px;
  color: var(--heading-color)
}

.profile-container .user-invite iframe {
  border-radius: 10px;
}

/* ******************** promotion-panel screen ***************************** */
.profile-container .promotion-panel ul li {
  word-spacing: 3px;
  font-weight: 600;
  margin-left: 25px;
  margin-bottom: 15px;
  font-size: 14px;
  text-align: left;
}

.profile-container .promotion-panel ul li::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0px;
  color: var(--first-color);
}

/* *********Styling applied to ApplicationProcess screen********************* */
.profile-container .application-process {
  width: 100%;
  color: var(--heading-color);
  text-align: center;
  margin: auto;
  font-weight: 600;
}

.profile-container .application-process img {
  display: block;
  margin: auto;
  width: 100px;
  margin-bottom: 20px;
}

/* *********Styling applied to MessagesPanel screen********************* */
.profile-container .messages-panel {
  width: 100%;
  font-weight: 500;
}

.messages-panel .msg-brief-container {
  display: flex;
  position: relative;
  padding: 15px 3px;
  border-bottom: 2px solid #f7f7fa;
  border-radius: 10px;
  transition: 0.2s;
}

.messages-panel .msg-brief-container.active {
  background-color: #f7f7fa;
}

.messages-panel .msg-brief-container:hover {
  background-color: #f7f7fa;
  cursor: pointer;
}

.messages-panel .msg-brief-container img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
}

.messages-panel .msg-brief-container h6 {
  color: var(--first-color);
  font-size: 15px;
  font-weight: 600;
}

.messages-panel .msg-brief-container span {
  color: #60cc8c;
  border: none;
  background: rgba(130, 240, 174, 0.2);
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 10px;
}

.messages-panel .all-msgs-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.messages-panel .all-msgs-container .send-msgchat {
  padding: 10px 0px 20px 50px;
}

/* .messages-panel .conv-scope {

} */
/* *********msg body********************* */
.msg-body-container {
  display: flex;
  position: relative;
  flex-direction: row;
}

.msg-body-container .img-container {
  width: 50px;
}

.msg-body-container .img-container img {
  display: block;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 10px 0;
}

.msg-body-container .msg-txt {
  color: var(--heading-color);
  font-size: 13px;
  padding: 15px;
  background-color: #f7f7fa;
  text-align: left;
  margin: 10px 0;
  border-radius: 10px;
  white-space: pre-line;
}

.msg-body-container.myMsg .msg-txt {
  background-color: #f2ecff;
  white-space: pre-line;
}

.msg-body-container.myMsg img {
  visibility: hidden;
}

.height-control {
  min-height: 400px;
  max-height: 500px;
  overflow-y: scroll;
}

/* Media */
@media (max-width: 900px) {
  .profile-container .columns-wrapper {
    flex-direction: column;
  }

  .profile-container .quarter-1-4 {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .profile-container .quarter-3-4 {
    width: 100% !important;
  }

}