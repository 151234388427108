.benefits {
  margin-bottom: 60px;
  color: var(--heading-color);
}

.benefits .txt_wrapper {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.benefits .relative {
  position: relative;
}

.benefits .txt_wrapper h4 {
  min-height: 65px;
  font-size: 24px;
  font-weight: 600;
  max-width: 255px;
}

.benefits .txt_wrapper p {
  max-width: 500px;
}

.benefits .icon {
  border-radius: 10px;
  opacity: 0.8;
}