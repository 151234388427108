.partnership-stripes-container {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}
.partnership-stripes-container .partnership-stripe {
  margin-bottom: 25px;
  background-color: var(--page-background);
  border-radius: 10px;
  padding: 20px;
}
.partnership-stripes-container .partnership-stripe .details-wrapper {
  display: flex;
  width: 100%;
  padding: 20px 0px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.partnership-stripes-container .partnership-stripe .details-wrapper .details-unit {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 175px;
  color: var(--heading-color);
  position: relative;
  height: 155px;
}
.partnership-stripes-container .partnership-stripe .details-wrapper .details-unit P {
  font-size: 12px;
  position: absolute;
  bottom: 7px;
  margin-bottom: 0px;
}
.partnership-stripes-container .partnership-stripe .details-wrapper .details-unit h6.value {
  font-weight: 600 !important;
}
.partnership-stripes-container .partnership-stripe .details-wrapper .details-unit .icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.partnership-stripes-container .head {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  width: 100%;
  margin-bottom: 10px;
}
.partnership-stripes-container .head .title {
  color: var(--heading-color);
  font-size: 20px;
}
.partnership-stripes-container .head .edit-tools i {
  color: #0d6efd;
  margin-right: 10px;
  cursor: pointer;
  font-size: 20px;
}
.partnership-stripes-container .head .edit-tools i.removal {
  color: var(--cancel-color);
}
.partnership-stripes-container .head .edit-tools i:hover {
  opacity: 0.7;
}
.partnership-stripes-container .partnership-applications .requests {
  color: var(--first-color);
  padding: 10px 5px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.2s;
  margin-top: 10px;
}
.partnership-stripes-container .partnership-applications .requests:hover {
  background-color: white;
}
.partnership-stripes-container .partnership-applications .requests h5 {
  font-weight: 600;
  margin-bottom: 0px;
}
.partnership-stripes-container .partnership-applications .requests i {
  color: #0d6efd;
  font-size: 35px;
  transition: 0.5s;
  margin-right: 7px;
}
.partnership-stripes-container .partnership-applications .requests.active i {
  transform: rotate(180deg);
}
.partnership-stripes-container .partnership-applications .wrapper {
  display: flex;
  height: 0px;
}
.partnership-stripes-container .partnership-applications .wrapper.active {
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 10px 0;
  transition: 0.5s;
  width: 100%;
  margin: auto;
}
.partnership-stripes-container .partnership-applications .wrapper .application-info {
  margin: 20px 0;
  background: #ffffff;
  padding: 10px;
  border-radius: 10px;
  visibility: collapse;
}
.partnership-stripes-container .partnership-applications .wrapper.active .application-info {
visibility: visible;
flex-direction: column;
position: relative;
}
.partnership-stripes-container .partnership-applications .wrapper.active .application-info .approve {
  position: absolute;
  right: -10px;
  top: 25%;
  font-size: 24px;
  color: var(--second-color);
  cursor: pointer;
}
.partnership-stripes-container .partnership-applications .wrapper.active .application-info .disapprove {
  position: absolute;
  right: -10px;
  bottom: 25%;
  font-size: 24px;
  color: var(--cancel-color);
  cursor: pointer;
}
.partnership-applications .wrapper.active .application-info .approve:hover,
.partnership-applications .wrapper.active .application-info .disapprove:hover {
  opacity: 0.7;
}
.partnership-applications .wrapper.active .application-info .state {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #3d9c63;
  font-weight: 500;
  border: none;
  background: #82f0ae40;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  transition: 0.2s;
}
.partnership-applications .wrapper.active .application-info .state i {
  font-size: 14px;
  color: var(--cancel-color);
  cursor: pointer;
  margin-left: 5px;
}
.partnership-applications .wrapper.active .application-info .state i:hover {
  opacity: 0.7;
}