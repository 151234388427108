.promotion-panel {
  color: var(--heading-color);
}
.promotion-panel ul {
  position: relative;
  list-style: none;
}
.user-promotion {
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.user-promotion .message-info ul {
  position: relative;
}
.user-promotion .congratulation-statement {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}
.socialmedia-sharing {
  padding: 30px 20px;
  background-color: rgb(248, 248, 248);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
}

.socialmedia-sharing .grant-code-wrapper {
  color: var(--heading-color);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.socialmedia-sharing .grant-code {
  font-weight: normal;
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
}
.socialmedia-sharing .grant-code span {
  font-weight: normal;
  font-weight: bold;
  margin-top: 5px;
  font-size: 18px;
}

.socialmedia-sharing-wrapper {
  display: flex;
  flex-direction: column;
}
.socialmedia-sharing-wrapper p {
  font-size: 14px;
  color: var(--heading-color);
}
.socialmedia-sharing-box {
  text-align: left;
  width: 100%;
  display: flex;
}
.socialmedia-sharing-box button {
  margin-right: 30px;
}
.socialmedia-sharing-box i {
  width: 50px;
  height: 50px;
  float: left;
  background-color: var(--first-color);
  font-size: 30px;
  color: #ffffff;
  transition: 0.3s;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.socialmedia-sharing-box i:hover {
  color: var(--second-color);
  box-shadow: 3px 3px 10px 0 rgb(255, 255, 255);
}