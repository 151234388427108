.links-section {
  background: #fff;
  border-radius: 20px;
  padding: 25px;
  position: relative;
  top: -47px;
  box-shadow: 0px 2px 7px 0 #c4c4c4;
  text-transform: capitalize;
}
.links-section .nav-link {
  color: #604cac82;
  font-weight: 600;
  cursor: pointer;
}
