.instructor-roles {
  padding: 50px 0;
  background: #cdc8ca1f url(../../images/clear-path/clear-path-bac.png)
    center center/cover no-repeat;
  background-position: bottom;
  background-size: contain;
}
.instructor-roles .roles-container {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  color: var(--heading-color);
  display: flex;
  flex-direction: column;
}

.instructor-roles .roles-container h4 {
  margin-top: 30px;
}
.instructor-roles .roles-container h6 {
  color: var(--first-color);
}
.instructor-roles .roles-container ul {
  margin-bottom: 20px;
}

.instructor-roles .roles-container ul li {
  color: var(--heading-color);
  font-size: 15px;
  margin: 5px 30px;
  line-height: 1.8;
}
.instructor-roles .roles-container ul li::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0;
  color: var(--first-color);
}
