.skills-rate-stars ul {
  display: flex;
  list-style-type: none;
  padding-left: 10px !important;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}
.skills-rate-stars ul li {
  /* display: inline-block; */
  color: var(--first-color);
  cursor: pointer;
}
.skills-rate-stars ul li:hover {
  color: var(--first-color) !important;
}