.hero-img {
  background: url(../../../images//welcome.webp) center center/cover no-repeat;
  min-height: 65vh;
  position: relative;
  display: flex;
  transform: translateY(-16px);
  padding: 80px 20px;
}

.hero-img video {
  border-radius: 15px;
  display: block;
  margin: auto;
}

.hero-img .beta-version {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 200px;
  height: 200px;
}

.hero-img h4,
h3 {
  color: rgb(224, 224, 224);
  font-weight: 600;
}

.hero-img .hero-info {
  max-width: 700px;
  background-color: #5f4cac7f;
  padding: 20px;
  border-radius: 15px;
}

.hero-img .hero-info h2 {
  color: #fff;
  font-size: 38px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.8;
}

.hero-img .hero-info h2 span {
  color: var(--second-color);
}

.hero-img .hero-info p {
  line-height: 1.8;
  color: #fff;
}

/* === Start Media Query ===*/

@media (width < 900px) {
  .hero-img {
    min-height: 75vh;
  }

  .hero-img .hero-info {
    text-align: start !important;
    padding: 7px;
    top: 20%;
    width: 80%;
  }

  .hero-img .hero-info h2 {
    font-size: 26px;
  }

  .hero-img .hero-info p {
    font-size: 13px;
  }
}