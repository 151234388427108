.accordion--container {
  display: flex;
  padding: 10px;
  color: var(--heading-color);
  flex-direction: column;
}

.accordion--container .title {
  border: 1px solid rgba(106, 106, 106, 0.2);
  border-radius: 10px;
  box-shadow: 3px 3px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  padding: 15px 50px 15px 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-weight: bold;
  cursor: pointer;
  margin-top: 10px;
}
.accordion--container .title .text  {
  margin-bottom: 0px;
  padding: 10px 0px 10px 50px;
}
.accordion--container .title.active .text {
  color: var(--first-color);
  border-left: 4px solid var(--second-color);
}

.accordion--container .title i {
  color: var(--second-color);
  font-size: 40px;
  transition: 0.5s;
}
.accordion--container .title.active i {
  transform: rotate(180deg);
}
.accordion--container .content {
  display: flex;
  height: 0px;
  background-color: #eae6ff80;
}
.accordion--container .content ul {
  visibility: collapse;
}
.accordion--container .content.active {
  height: fit-content;
  padding: 20px;
  transition: 0.5s;
  width: 99%;
  margin: auto;
}
.accordion--container .content.active ul {
  visibility: visible;
  flex-direction: column;
}
/* .accordion--container .content.active ul li {
  margin: 15px 20px;
} */
.accordion--container .content.active ul li {
  color: var(--heading-color);
  font-size: 15px;
  margin: 13px 30px;
  line-height: 1.8;
}
.accordion--container .content.active ul li::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0;
  color: var(--first-color);
}
