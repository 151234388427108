.partnerships-editor {
  padding: 50px 0;
  background: #cdc8ca1f url(../../images/clear-path/clear-path-bac.png)
    center center/cover no-repeat;
  background-position: bottom;
  background-size: contain;
}
.partnerships-editor .partnership-content {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 40px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  color: var(--heading-color);
}
.partnerships-editor .partnership-content .partnership-form {
  display: flex;
  flex-direction: column;
}
.partnerships-editor .partnership-content .partnership-form h4 {
  color: var(--first-color);
  font-weight: 600;
  margin-bottom: 0px;
}
.partnerships-editor .partnership-content .partnership-form .row {
  padding: 20px 0;
  /* border-bottom: 1px solid rgba(106, 106, 106, 0.2); */
}
/* .partnerships-editor .partnership-content .partnership-form .row.noborder {
  border-bottom: unset;
} */
.partnerships-editor .partnership-content .highlighted-title {
  font-size: 18px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--first-color);
  background-color: var(--tag-background);
  width: fit-content;
  margin-top: 10px;
}
.partnerships-editor .partnership-content .partnership_input {
  border-radius: 5px;
  background-color: #fafafa;
  border: none;
  height: 45px;
  padding: 5px 10px;
  font-size: 14px;
  color: var(--heading-color);
  border: 1px solid rgba(106, 106, 106, 0.2);
  width: 100%;
}
.partnerships-editor .partnership-content .partnership_input.nowidth {
  width: unset;
}
.partnerships-editor .partnership-content textarea {
  min-height: 70px;
}
.partnerships-editor .partnership-content .form-select {
  background-image: url(../../images/arrow/arrow-2.png);
  background-size: 17px 10px;
  background-repeat: no-repeat;
  background-position: right 10px center;
  box-shadow: none !important;
  /* margin: 20px auto 5px; */
}
.partnerships-editor .partnership-content .form-select:hover {
  cursor: pointer;
}

.partnerships-editor .partnership-content .partnership_input:focus-visible {
  outline: none;
  border: solid 1px var(--first-color);
  font-weight: 500;
  color: black;
  background-color: rgba(126, 240, 172, 0.2);
}

.partnerships-editor .partnership-content label {
  margin-top: 15px;
  margin-bottom: 3px;
  font-size: 15px;
}
.partnerships-editor .partnership-content label span {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #a1a1a1;
}
.partnerships-editor .partnership-content .actions-section {
  padding: 20px 0px;
}
.partnerships-editor .partnership-content .sentences p i {
  color: var(--cancel-color);
  margin-left: 5px;
  cursor: pointer;
}
.partnerships-editor .partnership-content .sentences p i:hover {
  opacity: 0.7;
}