.multi-selector {
  margin: 15px auto;
}
.multi-selector .options-container {
  display: flex;
  margin: auto;
  width: fit-content;
}
.multi-selector h6 {
  color: var(--heading-color);
  font-size: 14px;
  margin-bottom: 20px;
  text-align: center;
}
.multi-selector h6.small-bold-left {
  font-size: 13px;
  margin-bottom: 5px;
  text-align: left;
  font-weight: bold;
}
.multi-selector .options-container.horizontal {
  flex-direction: row;
  width: 100%;
}
.multi-selector .options-container.horizontal.fit-width {
  width: fit-content;
}
.multi-selector .options-container.vertical {
  flex-direction: column;
}
.multi-selector .selected-val {
  padding: 7px;
  display: flex;
  width: 200px;
  text-align: center;
  border: 2px solid rgb(235, 235, 235);
  color: var(--heading-color);
  font-weight: 600;
  font-size: 14px;
  background-color: rgb(245, 245, 245);
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
}
.multi-selector .selected-val:hover {
  background-color: rgba(95, 76, 172, 0.3);
}
.multi-selector .selected-val p {
  margin-bottom: 0px;
}
.multi-selector .selected-val.active {
  background-color: rgba(95, 76, 172, 0.3);
  border: 2px solid rgba(95, 76, 172, 0.6);
}
.multi-selector .selected-val.active p {
  color: var(--first-color) !important;
}
