.message-bar {
  display: flex;
  width: 100%;
  position: relative;
  padding: 1rem;
  margin: 1rem auto;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: opacity .2s linear;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.message-bar.fixed-bottom {
  position: fixed;
  bottom: 10px;
  z-index: 100;
  width: 80%;
  margin: auto;
}
.message-bar.mini-msg {
  font-size: 14px;
  padding: 0.5rem;
  margin: 0.5rem auto;
}
.message-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.message-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.message-error {
  color: #7c000b;
  background-color: #fd99a2;
  border-color: #c26870;
}

.message-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.txt-left {
  text-align: left;
  font-size: 13px;
  font-weight: 500;
}
.smaller-font {
  font-size: 14px;
}