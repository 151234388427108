.jobsearch-section .form-select,
.jobsearch-section .form-control,
.jobsearch-section .input-group-text {
  color: var(--first-color);
  font-weight: 600;
  font-size: 15px;
  background: #fafafa;
  border: none;
  box-shadow: none !important;
  padding: 12px;
  border-radius: 5px;
}
.jobsearch-section .row {
  align-items: flex-end;
}
.jobsearch-section .form-select {
  background-image: url(../../images/arrow/arrow-2.png);
  background-size: 14px 9px;
  background-repeat: no-repeat;
  background-position: right 7.25px center;
  box-shadow: none !important;
}
.jobsearch-section .form-control {
  font-weight: normal;
}
