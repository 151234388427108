/* == Start Globale Style == */
:root {
  --first-color: #604cac;
  --first-color-light: #ebe6ff;
  --tag-background: #604cac1a;
  --second-color: #7ef0ac;
  --heading-color: #6a6a6a;
  --heading-color-light: #c8c8c8;
  --hover-color: #00bfff;
  --cancel-color: #FF3A46;
  --edit-color: #4949ff;
  --page-background: #eae6ff80;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  margin: 15px 0;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: var(--first-color);
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--second-color);
}

/* Quick styling */
.firstcolor {
  color: var(--first-color) !important;
}

.secondcolor {
  color: var(--second-color) !important;
}

.headingcolor {
  color: var(--heading-color) !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.strong {
  font-weight: 600;
}

.ultra-strong {
  font-weight: 700;
  color: rgb(88 88 88);
}

.light {
  font-weight: 400;
}

.main-color {
  color: var(--first-color);
}

.secondary-color {
  color: var(--second-color);
}

.text-color {
  color: var(--heading-color);
}

.largefont {
  font-size: large;
}

.xxlargefont {
  font-size: xx-large;
}

body {
  font-family: "Poppins", sans-serif !important;
  padding-top: 65px;
}

a {
  text-decoration: none !important;
}

ol,
ul {
  padding-left: 0rem !important;
}

iframe {
  background: #5b39add6;
  padding: 10px;
}

.form-check-input {
  cursor: pointer;
}

input::placeholder {
  color: #b6b6b6 !important;
}

.different-header-color {
  color: var(--second-color) !important;
}

.warning-header-color {
  color: #ffa6aa !important;
  font-size: 13px !important;
}

.shadowed {
  color: var(--heading-color);
}

.relative {
  position: relative;
}

.language-switch-button {
  position: absolute;
  padding: 10px 30px;
  border-radius: 25px 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  bottom: 70px;
  right: 30px;
  font-weight: 600;
  transition: 0.2s;
  cursor: pointer;
}

.language-switch-button:hover {
  background-color: rgb(0, 0, 0);
}

.edit-symbol {
  color: var(--edit-color) !important;
}

.highlight-txt {
  color: var(--first-color);
  font-weight: bold;
}

.cards-separator {
  border-right: solid 1px rgb(220 220 220);
}

.cards-separator-left {
  border-left: solid 2px rgb(210 210 210);
}

.img-float-right {
  /* height: 400px; */
  width: 600px;
  border-radius: 20px;
  display: block;
  float: right;
}

.img-float-left {
  /* height: 400px; */
  width: 600px;
  border-radius: 20px;
  display: block;
  float: left;
}

.flex {
  display: flex !important;
}

.row-tags {
  display: flex;
  flex-direction: row;
}

.row-tags.wrap {
  flex-wrap: wrap;
}

.row-tags.sentences {
  flex-direction: column;
}

.row-tags p {
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 5px 7px;
  border-radius: 5px;
  color: var(--first-color);
  background-color: var(--tag-background);
  width: fit-content;
  margin-right: 8px;
  margin-bottom: 5px;
}

.row-tags.grayscale-tags {
  flex-wrap: wrap;
}

.row-tags.grayscale-tags p {
  color: var(--heading-color);
  background-color: #dedede;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-top: 0px;
  padding: 3px 5px;
}

.row-tags.wrap p {
  margin-top: 10px;
}

.row-tags.sentences p {
  font-size: 14px;
  margin-bottom: 10px;
}

.row-tags.skills p {
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: unset;
}

.tag-removal-icon {
  color: var(--cancel-color);
  margin-left: 5px;
  cursor: pointer;
}

.form-control:focus {
  box-shadow: unset !important;
}

/* General styling for sections*/
.start-up {
  background: #cdc8ca1f url(../../frontend/src/images/real-back.png) center center/cover no-repeat;
  padding: 80px 0;
}

.start-up h2 {
  font-weight: 600;
}

.start-up p {
  line-height: 2;
}

@media (max-width: 575.98px) {
  .start-up p {
    width: 100% !important;
  }
}


/* Start common heading style for all pages */
.common-heading {
  position: relative;
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: var(--first-color);
}

label.common-heading {
  width: 100%;
}

.common-heading span {
  font-weight: 100;
}

.common-heading:before,
.common-heading:after {
  content: "";
  position: absolute;
  bottom: 0px;
  width: 140px;
  height: 1px;
  background: #ccc;
  display: block;
  left: 0px;
  right: 0px;
  margin: 0px auto;
}

.common-heading:after {
  width: 50px;
  height: 1px;
  background: var(--first-color);
}

/* General style for input fields in the project */
.form-control.general-input--style {
  border-radius: 5px;
  background-color: #fafafa;
  border: none;
  height: 45px;
  padding: 5px 10px;
  font-size: 14px;
  color: var(--heading-color);
  border: 1px solid rgba(106, 106, 106, 0.2);
}

textarea.form-control.general-input--style {
  min-height: 120px;
}

.form-control.general-input--style:focus-visible {
  outline: none;
  border: solid 1px var(--first-color);
  font-weight: 500;
  color: black;
  background-color: rgba(126, 240, 172, 0.2);
}

.general-input--style-label {
  margin-bottom: 3px;
  font-size: 15px;
  text-transform: capitalize;
  color: var(--heading-color);
  font-weight: 500;
}

.general-input--style-label span {
  font-size: 11px;
  font-weight: 400;
}

.disabled-element {
  opacity: 0.5 !important;
}

.disabled-element:hover {
  opacity: unset !important;
}

.reset-button {
  padding: 5px 10px;
  border: 1px solid var(--first-color);
  border-radius: 5px;
  background-color: white;
  color: var(--first-color);
  cursor: pointer;
  font-size: 12px;
  margin-left: 10px;
  font-weight: 600;
}

.reset-button:hover {
  opacity: 0.7;
}

.general-cancel-button-modal {
  width: 100%;
  height: fit-content;
}

.general-cancel-button-modal .cancel-wrapper {
  width: fit-content;
  float: right;
  cursor: pointer;
}

.general-cancel-button-modal img {
  display: block;
  height: 20px;
  width: 20px;
}

.general-cancel-button-modal img:hover {
  opacity: 0.7;
}

/* General standard style for lists in the whole project */
.general-list-style ul li::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0;
  color: var(--first-color);
}

.general-list-style ul li {
  color: var(--heading-color);
  font-size: 15px;
  margin-left: 28px;
  margin-bottom: 13px;
  line-height: 1.8;
}

/* display help information on hover with special style */
.styled-help-info i {
  color: var(--second-color);
  margin-left: 7px;
}

.styled-help-info.align-left i {
  font-size: 25px;
  color: var(--first-color);
}

.styled-help-info i:hover {
  cursor: help;
  position: relative;
}

.styled-help-info i span {
  display: none;
  font-family: system-ui;
  letter-spacing: 1px;
  white-space: pre-line;
  line-height: 1.4;
  text-align: left;
}

.styled-help-info i:hover span {
  padding: 15px;
  border-radius: 10px;
  display: block;
  font-size: 12px;
  color: #ffffff;
  background-color: var(--first-color);
  z-index: 100;
  left: 5px;
  margin: 10px;
  width: 300px;
  position: absolute;
  top: 10px;
  text-decoration: none;
}

.styled-help-info.align-left i:hover span {
  right: 5px;
  width: 400px;
  left: unset;
  font-size: 13px;
  font-weight: 400;
}

/* Modal screen window */
.modal-window {
  position: fixed;
  top: 0px;
  left: 0px;
  margin-top: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
}

.modal-window.box-fitting-spinner {
  position: relative;
  background-color: unset;
}

.modal-window .spinner-border {
  width: 100px;
  height: 100px;
  color: white !important;
}

.modal-window.box-fitting-spinner .spinner-border {
  width: unset;
  height: unset;
}

/* Organizing demo cards for static pages */
.how-works h2 {
  color: var(--first-color);
  font-weight: 600;
}

.how-works .card .img-wrapper {
  position: relative;
  width: fit-content;
  margin: auto;
}

.how-works .card .img-wrapper .arrangement {
  position: absolute;
  background-color: var(--first-color);
  border-radius: 50%;
  color: white;
  width: 45px;
  height: 45px;
  bottom: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-works .card img {
  width: 90px;
}

.how-works .card-title {
  color: var(--heading-color);
  font-weight: 600;
  line-height: 1.5;
}

.how-works .card-title.spaecial-height {
  height: 75px;
}

.how-works .card-text {
  line-height: 2;
  color: var(--heading-color);
}

.how-works .card {
  border: none;
}

.moveIcon {
  cursor: grab !important;
}

.removeIcon {
  cursor: pointer;
  color: #FF3A46;
}

/* General keys for resetting values in the whole project */
.no-height {
  height: unset !important;
}

.no-width {
  width: unset !important;
}

.full-width {
  width: 100% !important;
}

.right-border {
  border-right: 2px solid #e8e8e8;
}

.fit-content {
  width: fit-content;
  margin: auto;
}


@media (max-width: 700px) {
  .hidden {
    display: none;
  }
}