.starter-instructor {
  padding: 50px 0;
}
.starter-instructor .tracks-descripe {
  width: 60%;
  margin: 0px auto 40px;
  color: var(--heading-color);
  text-align: center;
}
.starter-instructor h2, .starter-instructor h4 {
  padding: 20px 0 10px 0;
  color: var(--first-color);
  font-weight: 600;
  margin-top: 10px;
}
