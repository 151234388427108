.card-job-info {
  width: 410px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 3px 3px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 30px;
  cursor: pointer;
  transition: 0.3s;
}
.card-job-info:hover {
  background-color: rgba(96, 76, 172, 0.15);
}
.card-job-info img {
  height: 45px;
}
.card-job-info h5 {
  color: var(--first-color);
  font-size: 16px;
  font-weight: 600;
}
.card-job-info span {
  color: #b6b5b5;
  font-size: 12px;
}