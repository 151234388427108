.navbar {
  /* background: linear-gradient(270deg, #6b3eb8eb 0%, #5c3ab0 100%) !important; */
  background: linear-gradient(270deg, #5c3ab0 0%, #2c1e47 100%) !important;
  padding: 0 !important;
  z-index: 5 !important;
}

.navbar .navbar-brand img {
  width: 135px;
}

.navbar .nav-link {
  color: #fff !important;
  font-size: 14px;
}

.navbar .nav-link:hover {
  color: var(--second-color);
}

.navbar .active {
  color: var(--second-color) !important;
}

.dropdown-item {
  cursor: pointer;
  padding: 0.7rem 1rem !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background: var(--first-color) !important;
}

.dropdown-menu a {
  font-size: 14px;
}

.navbar .custom-nav-btn {
  border: none;
  padding: 5px 30px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  background: var(--second-color);
  color: var(--first-color);
  margin-bottom: 15px;
}

.navbar-toggler {
  box-shadow: none !important;
}

.navbar-nav {
  margin: 10px;
}

.navbar-nav .nav-item .tap_info {
  padding: 0 7px;
  text-align: center;
  color: var(--first-color);
  font-size: 12px;
  font-weight: 600;
}

.navbar-nav .nav-item .tap_info p {
  padding-bottom: 7px;
  border-bottom: 2px solid #d3d3d3;
  margin-bottom: 2px !important;
}

/* === Start Media Query ===*/
@media (min-width: 768px) and (max-width: 1100px) {
  .navbar-nav .nav-link {
    font-size: 13px;
    padding: 0 !important;
  }

  .navbar .custom-nav-btn {
    padding: 3px 20px;
    font-size: 12px;
  }
}

@media (min-width: 991.98px) {
  .dropdown .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    transition: 0.3s ease all;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
    transition: 0.5s ease all;
  }
}